import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PwLayoutModule } from 'pw-lib';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';

@NgModule({
  declarations: [NavMenuComponent, PageWrapperComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    PwLayoutModule,
    MatRippleModule,
  ],
  exports: [NavMenuComponent, PageWrapperComponent, PwLayoutModule],
})
export class LayoutModule {}
