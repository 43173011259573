import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-input',
  templateUrl: './formly-input.component.html',
  styleUrls: ['./formly-input.component.scss'],
})
export class FormlyInputComponent extends FieldType {
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;

  get type(): string {
    return this.to.type || 'text';
  }
}
