import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 앱 설치 여부 확인 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AppAvailabilityService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 앱의 설치 여부를 `url`로 확인
   *
   * @param url Android: 패키지명, iOS: 앱의 Url Scheme
   */
  async checkAvailability(url: string): Promise<boolean> {
    return this._flutterBridge.callHandler('app-availability', url).then((res) => res != null);
  }
}
