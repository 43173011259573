import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { PwCoachMarkDirective } from './pw-coach-mark.directive';

export declare const PW_COACH_MARK_SHOW_CLOSE_BTN: InjectionToken<boolean>;

@NgModule({
  declarations: [PwCoachMarkDirective],
  imports: [CommonModule],
  exports: [PwCoachMarkDirective],
})
export class PwCoachMarkModule {}
