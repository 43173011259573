import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService, SystemPadding } from '../../services/bridge/app.service';

@Component({
  selector: 'pw-viewinsets',
  templateUrl: './pw-viewinsets.component.html',
  styleUrls: ['./pw-viewinsets.component.scss'],
})
export class PwViewInsetsComponent implements OnInit, OnDestroy {
  /**
   * 시스템 padding
   */
  systemPadding$: Promise<SystemPadding>;

  windowResized: () => void;

  constructor(private _appService: AppService) {}

  ngOnInit(): void {
    this.systemPadding$ = this._appService.getViewInsets();
    this.windowResized = this.onWindowResized.bind(this);
    this._appService.addWindowResized(this.windowResized);
  }

  onWindowResized(): void {
    this.systemPadding$ = this._appService.getViewInsets();
  }

  ngOnDestroy(): void {
    this._appService.removeWindowResized(this.windowResized);
  }
}
