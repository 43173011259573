<div class="locale-row" *ngFor="let locale of locales">
  <div class="locale-txt" [ngClass]="{ 'top-label': labelPosition === 'TOP' }">
    <pw-locale-icon [locale]="locale"> </pw-locale-icon>
    <span
      class="locale-txt-value"
      [innerText]="localeDatas[locale.value] ?? '-'"
    ></span>
  </div>
  <hr />
</div>
