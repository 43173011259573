import { COMMA, ENTER, SLASH } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, of } from 'rxjs';
import { map, mergeMap, startWith, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-formly-chips',
  templateUrl: './formly-chips.component.html',
  styleUrls: ['./formly-chips.component.scss'],
})
export class FormlyChipsComponent extends FieldType implements OnInit {
  visible = true;

  selectable = true;

  removable = true;

  // separatorKeysCodes: number[] = [ENTER, COMMA, SLASH, ESCAPE, MAC_ENTER];
  separatorKeysCodes: number[] = [ENTER, COMMA, SLASH];

  textCtrl = new FormControl();

  filteredItems: Observable<string[]>;

  chips: Set<string>;

  @ViewChild('chipsInput') chipsInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.attachFilter();

    if (this.formControl.value != null) {
      // 이전 model의 값이 있다면 다시 Chip 생성
      const v = this.formControl.value;
      if (this.chips == null) {
        this.chips = new Set();
        if (v && v.forEach) {
          v.forEach((text) => this.chips.add(text));
        }
      }
    }
    this.formControl.valueChanges //
      .pipe(take(1))
      .subscribe((v) => {
        if (this.chips == null) {
          this.chips = new Set();
          if (v && v.forEach) {
            v.forEach((text) => this.chips.add(text));
          }
        }
      });
  }

  attachFilter(): void {
    this.filteredItems = this.textCtrl.valueChanges.pipe(
      startWith<any, any>(null),
      mergeMap((text: string | null) => {
        return text && text.length > 0 ? this.filter$(text) : this.filter$('');
      })
    );
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;

    if (!this.chips) {
      this.chips = new Set();
    }
    // add value
    if ((value || '').trim()) {
      this.chips.add(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.textCtrl.setValue(null);
    this.formControl.patchValue(Array.from(this.chips));
  }

  remove(deletedItem: string): void {
    this.chips.delete(deletedItem);

    this.formControl.patchValue(Array.from(this.chips));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.chips) {
      this.chips = new Set();
    }
    this.chips.add(`${event.option.value}`);
    this.chipsInput.nativeElement.value = '';
    this.textCtrl.setValue(null);
    this.formControl.patchValue(Array.from(this.chips));
  }

  optionsData: string[];

  private filter$(text: string): Observable<string[]> {
    const options = this.optionsData
      ? of(this.optionsData)
      : (this.to.options as Observable<Array<string>>).pipe(
          tap((r) => {
            this.optionsData = r;
          })
        );
    // const options = this.to.options as Observable<Array<string>>;
    return options.pipe(
      map((items) => {
        return items.filter(
          (item) => item.toLowerCase().indexOf(text.toLowerCase()) === 0
        );
      })
    );
  }
}
