import { Component, Input, OnInit } from '@angular/core';

/**
 * 루비 태그 생성 컴포넌트
 *
 * 루비태그를 생성하고 텍스트 배열과 발음에 rp rt를 생성하여 넣는다.
 *
 * lang 값이 있으면 locale pipe 동작과 같이 유효한 json 일 때만 해당 언어에 해당하는 텍스트만 표시한다
 */
@Component({
  selector: 'pw-ruby',
  templateUrl: './pw-ruby.component.html',
  styleUrls: ['./pw-ruby.component.css'],
})
export class PwRubyComponent implements OnInit {
  /** 메인 텍스트 배열 */
  @Input()
  rb: string;

  /** 발음 텍스트 배열 */
  @Input()
  rt: string;

  /** pwLocale 파이프 적용이 필요할 때 사용 ko, ja 등의 언어로 번역한다 */
  @Input()
  lang?: string;

  /** 루비 텍스트 배열 */
  get rubyTexts(): { rb: string; rt: string }[] {
    let rb, rt;
    if (this.lang) {
      const rbJson = this.isJson(this.rb);
      rb = rbJson ? rbJson[this.lang] : this.rb;

      const rtJson = this.isJson(this.rt);
      rt = rtJson ? rtJson[this.lang] : this.rt;
    } else {
      rb = this.rb;
      rt = this.rt;
    }
    return [{ rb, rt }];
  }

  constructor() {}

  ngOnInit(): void {}

  /**
   * 유효한 json 일 때만 객체 반환
   */
  isJson(str): any | null {
    if (!str) {
      return str;
    }
    try {
      const j = JSON.parse(str);
      if (j && typeof j === 'object' && j !== null) {
        return j;
      }
    } catch (e) {}
    return null;
  }
}
