export class DevUtils {
  /**
   * 프로토타입을 바꾸지 않고 부모 객체를 자식에게 복사해주는식의 의사상속 지원 메소드,
   * 2개 이상의 클래스를 상속받을수 있게 해준다.
   *
   * @see https://www.typescriptlang.org/docs/handbook/mixins.html
   *
   * @param derivedCtor 자식(구현체) 클래스
   * @param baseCtors 부모 클래스 리스트
   */
  static applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach((baseCtor) => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
        Object.defineProperty(
          derivedCtor.prototype,
          name,
          Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
        );
      });
    });
  }
}
