import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 클립보드 설정, 조회하는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 클립보드에 저장 된 문자열 조회
   */
  async getClipboard(): Promise<string> {
    return this._flutterBridge.callHandler('clipboard', 'get');
  }

  /**
   * 클립보드에 값 설정
   *
   * @param value 클립보드에 설정할 값
   */
  async setClipboard(value: string): Promise<string> {
    return this._flutterBridge.callHandler('clipboard', 'set', value);
  }
}
