import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 알림
 *
 * Background 상태에서는 받아오지 않음
 */
export interface Notification {
  title: string;
  body: string;
}

/**
 * 푸시 데이터
 */
export interface PushData {
  // Background 상태에서는 받아오지 않음
  notification: Notification;
  data: any;
}

/**
 * Push 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * Push 토큰 조회
   */
  get pushToken(): Promise<string> {
    return this._flutterBridge.callHandler('getPushToken');
  }

  /**
   * 앱 실행 중에 푸시 알림을 받았을 때 이벤트
   * @param callback 처리할 작업
   */
  addPushListener(callback: (push: PushData) => void): void {
    window.addEventListener('push', (e: CustomEvent) => {
      callback(e.detail);
    });
  }

  /**
   * 푸시 알림을 통해서 앱을 실행했을 때 이벤트
   * @param callback 처리할 작업
   */
  addPushLaunchListener(callback: (push: PushData) => void): void {
    window.addEventListener('launchWithPush', (e: CustomEvent) => {
      callback(e.detail);
    });
  }

  /**
   * 백그라운드에서 앱 실행 중에 푸시 알림을 통해서 앱으로 이동했을 때 이벤트
   * @param callback 처리할 작업
   */
  addPushResumeListener(callback: (push: PushData) => void): void {
    window.addEventListener('resumeWithPush', (e: CustomEvent) => {
      callback(e.detail);
    });
  }
}
