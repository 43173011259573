<ruby>
  <ng-container *ngFor="let rubyText of rubyTexts">
    {{ rubyText.rb }}
    <rp>(</rp>
    <rt>
      {{ rubyText.rt }}
    </rt>
    <rp>)</rp>
  </ng-container>
</ruby>
