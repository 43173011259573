import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { PW_SUPPORTED_LOCALES } from '../../pw-locale';

/**
 * JSON {"언어키": "문자열"} 형식으로 되어있는 데이터를 각 언어별 현지화 표시값으로 보여주도록 하는 컴포넌트.
 *
 * 현재 전체 표시만 최소한 기능으로 개발했고, 화면 고도화는 예정.
 */
@Component({
  selector: 'pw-locale-viewer',
  templateUrl: './pw-locale-viewer.component.html',
  styleUrls: ['./pw-locale-viewer.component.scss'],
})
export class PwLocaleViewerComponent implements OnInit {
  /** 현지화 데이터. JSON string 형식이어야 한다 */
  @Input() data: string;

  /** 표시할 언어 목록 */
  @Input() locales: { name: string; value: string }[];

  /** 언어 라벨 표기 위치. 왼쪽 기본값, textarea 같은 경우는 위로 위치 시킬 수 있음 */
  @Input() labelPosition: 'TOP' | 'LEFT' = 'LEFT';

  /** 현지화 데어터 객체 */
  get localeDatas(): { [key: string]: string } {
    if (!this.data) {
      return {};
    }
    try {
      return JSON.parse(this.data);
    } catch (e) {
      const obj = {};
      this.locales.forEach((locale) => {
        obj[locale.value] = '';
      });
      obj[this.locales[0].value] = this.data;
      return obj;
    }
  }

  constructor(
    @Optional()
    @Inject(PW_SUPPORTED_LOCALES)
    private supportedLocales: { name: string; value: string }[]
  ) {}

  ngOnInit(): void {
    if (!this.locales) {
      this.locales = this.supportedLocales;
    }
  }
}
