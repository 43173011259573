import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-html-editor-dialog',
  templateUrl: './html-editor-dialog.component.html',
  styleUrls: ['./html-editor-dialog.component.scss'],
})
export class HtmlEditorDialogComponent implements OnInit {
  control: FormControl = new FormControl();

  imgUploadUrl: string = environment.fileServerUrl;

  constructor(
    public dialogRef: MatDialogRef<HtmlEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      value?: string;
    }
  ) {
    this.control.setValue(data.value);
    this.dialogRef.backdropClick().subscribe(() => this.onClickCancel());
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.onClickCancel();
      }
    });
  }

  ngOnInit(): void {}

  onClickSave(): void {
    this.dialogRef.close(this.control.value.replaceAll('<br>', '<br>\n'));
  }

  onClickCancel(): void {
    this.dialogRef.close(this.data.value);
  }
}
