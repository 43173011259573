import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 화면 밝기 설정하는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class BrightnessService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 설정 된 밝기를 0..1로 조회
   */
  async getBrigthness(): Promise<number> {
    return this._flutterBridge.callHandler('brightness', 'get');
  }

  /**
   * 밝기 값 설정
   *
   * @param brigthness 0..1 사이의 밝기
   */
  setBrightness(brigthness: number): Promise<number> {
    return this._flutterBridge.callHandler('brightness', 'set', brigthness);
  }
}
