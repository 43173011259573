import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import * as XLSX from 'xlsx';

/**
 * 엑셀 파일 입력 및 양식 출력 Dialog
 */
@Component({
  selector: 'app-excel-input',
  templateUrl: './excel-input.component.html',
  styleUrls: ['./excel-input.component.scss'],
})
export class ExcelInputComponent implements OnInit {
  @ViewChild('xlsxUpload') xlsxUpload: ElementRef<HTMLInputElement>;

  private readonly CJK_REGEX = new RegExp(
    `[${['Han', 'Katakana', 'Hiragana', 'Hangul', 'Bopomofo']
      .map((str) => `\\p{Script=${str}}`)
      .join()}]`,
    'gu'
  );

  private directory: FileSystemDirectoryHandle;

  constructor(
    public dialogRef: MatDialogRef<ExcelInputComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tableName: string;
      header: (string | { sheet: string; header: string[] })[];
    },
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  onUploadClick(): void {
    if (this.directory) {
      this.dialogRef.close(this.directory);
      return;
    }
    this.dialogRef.close(this.xlsxUpload.nativeElement.files[0]);
  }

  onFileClick(event: PointerEvent): void {
    if (window.showDirectoryPicker) {
      event.preventDefault();
      from(window.showDirectoryPicker()).subscribe({
        next: async (dir) => {
          this.directory = dir;
          // 대체 문법 없음
          // eslint-disable-next-line no-restricted-syntax
          for await (const file of dir.values()) {
            if (
              file.kind === 'file' &&
              file.name.normalize('NFC') ===
                `${this.translate.instant(
                  `REPO.TABLE.${this.data.tableName}._`
                )}.xlsx`
            ) {
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(
                await (<FileSystemFileHandle>file).getFile()
              );

              this.xlsxUpload.nativeElement.files = dataTransfer.files;
            }
          }
        },
        error: (e) => {
          console.warn(e);
        },
      });
    }
  }

  onFileChange(): void {
    this.cdr.markForCheck();
  }

  onClickExportForm(): void {
    const header: (string | { sheet: string; header: string[] })[] =
      this.data.header.map((h) => {
        if (typeof h === 'object') {
          return {
            ...h,
            header: h.header.map((h1) =>
              this.translate.instant(`REPO.TABLE.${h.sheet}.${h1}`)
            ),
          };
        }
        return this.translate.instant(`REPO.TABLE.${this.data.tableName}.${h}`);
      });
    const parentHeader: string[] = header.filter(
      (h) => typeof h !== 'object'
    ) as string[];
    const childHeader = header.filter((h) => typeof h === 'object') as {
      sheet: string;
      header: string[];
    }[];

    const parentWorksheet = XLSX.utils.aoa_to_sheet([parentHeader]);

    // 컬럼 너비 조정
    parentWorksheet['!cols'] = parentHeader.map((h) => {
      // CJK 문자는 1.8배 크게 조정
      const cjkCharLength = h.match(this.CJK_REGEX)?.length ?? 0;
      return {
        wch: Math.max(10, h.length - cjkCharLength + cjkCharLength * 1.8),
      };
    });

    const workbook = XLSX.utils.book_new();
    workbook.Sheets[this.data.tableName] = parentWorksheet;
    workbook.SheetNames.push(this.data.tableName);

    childHeader.forEach((h) => {
      const worksheet = XLSX.utils.aoa_to_sheet([h.header]);

      // 컬럼 너비 조정
      worksheet['!cols'] = parentHeader.map((h1) => {
        // CJK 문자는 1.8배 크게 조정
        const cjkCharLength = h1.match(this.CJK_REGEX)?.length ?? 0;
        return {
          wch: Math.max(10, h1.length - cjkCharLength + cjkCharLength * 1.8),
        };
      });

      workbook.Sheets[h.sheet] = worksheet;
      workbook.SheetNames.push(h.sheet);
    });

    XLSX.writeFile(
      workbook,
      `${this.translate.instant(`REPO.TABLE.${this.data.tableName}._`)}.xlsx`
    );
  }
}
