import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { PwCoachMarkModule } from './pw-coach-mark.module';

@Injectable({
  providedIn: PwCoachMarkModule,
})
export class PwCoachMarkService {
  /**
   * 현재 화면의 전체 코치마크를 표시할 지 여부
   */
  isShow = false;

  renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Optional()
    @Inject('showPwCoachMarkCloseBtn')
    private showCloseBtn: boolean
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.createCoachMarkContainer();
  }

  /**
   * Container 생성
   */
  private createCoachMarkContainer(): void {
    // Container 가 이미 있으면 생성하지 않는다.
    if (this.document.querySelector('.coach-mark-container')) {
      return;
    }
    const container = this.renderer.createElement('div');
    this.renderer.addClass(container, 'coach-mark-container');
    this.renderer.setStyle(container, 'position', 'fixed');
    this.renderer.setStyle(container, 'top', 0);
    this.renderer.setStyle(container, 'left', 0);
    this.renderer.setStyle(container, 'overflow', 'hidden');
    this.renderer.setStyle(container, 'width', '100%');
    this.renderer.setStyle(container, 'height', '0');
    this.renderer.setStyle(container, 'line-height', '1.5');
    this.renderer.setStyle(container, 'z-index', 100);
    this.renderer.setStyle(container, 'white-space', 'pre-wrap');
    this.renderer.setStyle(container, 'background-color', 'rgba(0,0,0,0.5)');

    // 애니메이션 추가
    const animation = 'height 400ms ease-in-out';
    this.renderer.setStyle(container, '-webkit-transition', animation);
    this.renderer.setStyle(container, '-o-transition', animation);
    this.renderer.setStyle(container, '-ms-transition', animation);
    this.renderer.setStyle(container, 'transition', animation);

    this.renderer.appendChild(this.document.body, container);

    // 닫기 버튼 생성하거나, 생성하지 않는다면 영역 아무 곳을 눌렀을 때 닫히게 한다.
    if (this.showCloseBtn) {
      const closeBtn = this.createCoachMarkCloseBtn(container);
      this.attachCloseBtnEvent(closeBtn);
    } else {
      this.attachCloseBtnEvent(container);
    }
  }

  /**
   * Container 에 표시할 닫기 버튼 생성
   */
  private createCoachMarkCloseBtn(container): any {
    // // .close-btn 이 있으면 생성하지 않는다.
    const closeBtn = this.renderer.createElement('div');
    closeBtn.innerHTML = '&times;';
    this.renderer.addClass(closeBtn, 'close-btn');
    this.renderer.setAttribute(closeBtn, 'role', 'button');
    this.renderer.setStyle(closeBtn, 'position', 'absolute');
    this.renderer.setStyle(closeBtn, 'top', '30px');
    this.renderer.setStyle(closeBtn, 'right', '30px');
    this.renderer.setStyle(closeBtn, 'font-size', '60px');
    this.renderer.setStyle(closeBtn, 'font-weight', 'bolder');
    this.renderer.setStyle(closeBtn, 'color', 'white');

    this.renderer.appendChild(container, closeBtn);

    return closeBtn;
  }

  /**
   * 닫기버튼 표시 여부에 따라 닫기버튼, 혹은 코치마크 표시 영역 전체에 닫기 이벤트를 추가한다
   *
   * @param element 닫기 이벤트를 표시할 요소
   */
  private attachCloseBtnEvent(element: any): void {
    // 버튼 요소에 닫기 이벤트 추가

    this.renderer.setStyle(element, 'cursor', 'pointer');
    this.renderer.listen(element, 'click', () => {
      this.hideCoachMark();
    });
  }

  /**
   * 코치마크를 표시 / 비표시한다
   */
  toggleCoachMark() {
    if (this.isShow) {
      this.hideCoachMark();
    } else {
      this.showCoachMark();
    }
  }

  /** 코치마크를 표시한다 */
  showCoachMark(): void {
    this.renderer.setStyle(
      this.document.querySelector('.coach-mark-container'),
      'height',
      '100%'
    );
    this.isShow = true;
  }

  /** 코치마크를 숨긴다 */
  hideCoachMark(): void {
    this.renderer.setStyle(
      this.document.querySelector('.coach-mark-container'),
      'height',
      '0'
    );
    this.isShow = false;
  }
}
