<div class="locale-row" *ngFor="let locale of supportedLocales">
  <div class="locale-txt">
    <pw-locale-icon [locale]="locale" (click)="input.focus()" matRipple>
    </pw-locale-icon>
    <mat-form-field class="locale-field-item">
      <input
        matInput
        #input
        class="locale-input"
        type="text"
        [ngModel]="getLocaleModel(locale.value)"
        (ngModelChange)="onChangeCurrentValue($event, locale.value)"
      />
    </mat-form-field>
  </div>
</div>
