import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { fadeInOut, rotate180, slideUpDown } from 'src/app/core/animation';
import { UiService } from 'src/app/core/services/ui.service';

export interface NavMenu {
  name: string;
  link?: string;
  icon?: string;
  disabled?: boolean;
  canShow$?: Observable<boolean>;
  /**
   * @example
   * ['/partner/*'] - * : 무엇이든 올수 있는 경우
   * @deprecated 사용하지 않고 angular router 기능 활용할 예정
   */
  activeWhen?: string[];
  children?: NavMenu[];
  class: string;
}

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  animations: [rotate180, slideUpDown, fadeInOut],
})
export class NavMenuComponent implements OnInit {
  @HostBinding('attr.aria-expanded') expanded: boolean;

  @Input() menu: NavMenu;

  @Input() depth = 0;

  @Output() clickMenu = new EventEmitter<void>();

  isRouterActive = false;

  hasChildren = false;

  constructor(private _router: Router, private _uiSerivce: UiService) {}

  ngOnInit(): void {
    this.hasChildren = this.menu.children && this.menu.children.length > 0;
    this._uiSerivce.currentUrl$.subscribe((url) => {
      if (this.menu.link && url) {
        const split = url.split(';')[0].split('?')[0];
        this.expanded = split.indexOf(this.menu.link) === 0;
        this.isRouterActive = this._getIsRouterActive(split);
      }
    });
  }

  onClickMenu(menu?: NavMenu): void {
    if (!menu) {
      this.clickMenu.emit();
      return;
    }
    if (this.hasChildren) {
      this.expanded = !this.expanded;
      return;
    }

    this._router.navigateByUrl(menu.link);

    this.clickMenu.emit();
  }

  private _getIsRouterActive(url: string): boolean {
    if (this.hasChildren) {
      return false;
    }

    if (this._router.isActive(this.menu.link, false)) {
      return true;
    }

    if (!this.menu.activeWhen || this.menu.activeWhen.length < 1) {
      return false;
    }

    let isRouterActive = true;

    this.menu.activeWhen.some((also) => {
      const splited1 = url.split('/');
      const splited2 = also.split('/');
      const length =
        splited1.length > splited2.length ? splited1.length : splited2.length;

      for (let i = 0; i < length; i += 1) {
        if (splited2[i] === undefined) {
          return true;
        }
        if (splited2[i] === '*') {
          return false;
        }
        if (splited2[i] !== splited1[i]) {
          isRouterActive = false;
          return true;
        }
      }

      return false;
    });

    return isRouterActive;
  }
}
