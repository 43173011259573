import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PwTuiEditorModule } from '../../../../public-api';
import { PwLocaleTuiEditorComponent } from './pw-locale-tui-editor.component';

@NgModule({
  declarations: [PwLocaleTuiEditorComponent],
  imports: [CommonModule, PwTuiEditorModule, MatIconModule, FormsModule],
  exports: [PwLocaleTuiEditorComponent],
})
export class PwLocaleTuiEditorModule {}
