import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwSafeAreaComponent } from './pw-safearea.component';

@NgModule({
  declarations: [PwSafeAreaComponent],
  imports: [CommonModule],
  exports: [PwSafeAreaComponent],
})
export class PwSafeareaModule {}
