import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AppService,
  BridgeService,
  PushService,
} from 'projects/pw-lib/src/public-api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DialogService } from '../components/dialog/dialog.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private pushService: PushService,
    private bridgeService: BridgeService,
    private appService: AppService,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | UrlTree
    | boolean {
    if (this.authService.loginInfo) {
      return true;
    }

    return this.authService.getNewAuth().pipe(
      map((token) => {
        if (token) {
          return true;
        }

        return this.router.parseUrl('/logout');
      }),
      catchError(() => {
        return of(this.router.parseUrl('/logout'));
      })
    );
  }
}
