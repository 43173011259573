import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  /**
   * Angular server side rendering 고려, 서버인지 브라우저인지 확인
   *
   * @readonly
   * @type {boolean}
   * @memberof PlatformService
   */
  get isPlatformBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Angular server side rendering 고려, 서버인지 브라우저인지 확인
   *
   * @readonly
   * @type {boolean}
   * @memberof PlatformService
   */
  get isPlatformServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}
