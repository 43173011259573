import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwViewInsetsComponent } from './pw-viewinsets.component';

@NgModule({
  declarations: [PwViewInsetsComponent],
  imports: [CommonModule],
  exports: [PwViewInsetsComponent],
})
export class PwViewInsetsModule {}
