import { IConfig, initialConfig } from 'ngx-mask';

/**
 * @see https://github.com/JsDaddy/ngx-mask
 */
export const maskConfig: Partial<IConfig> = {
  ...initialConfig,
  patterns: {
    ...initialConfig.patterns,
    // 한글, 영어, 숫자, 필수입력
    A: {
      pattern: new RegExp('[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]'),
    },
    // 한글, 영어, 숫자, 선택입력
    a: {
      pattern: new RegExp('[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]'),
      optional: true,
    },
    // 한글, 영어, 필수입력
    S: {
      pattern: new RegExp('[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]'),
    },
    // 한글, 영어, 선택입력
    s: {
      pattern: new RegExp('[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]'),
      optional: true,
    },
    // 공백불가, 필수입력
    B: { pattern: new RegExp('[\\S]') },
    // 공백불가, 선택입력
    b: { pattern: new RegExp('[\\S]'), optional: true },
  },
  // 1,000,000
  thousandSeparator: ',',
  // 기본적으로 특수문자 입력 불가
  specialCharacters: [],
};
