import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PW_SUPPORTED_LOCALES } from '../../pw-locale';

/**
 * 현지화 필드(DB 에 json 형식, 각 키는 언어 코드로 구성하는 데이터)
 */
@Component({
  selector: 'pw-locale-input-cva',
  templateUrl: './pw-locale-input.component.html',
  styleUrls: ['./pw-locale-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => PwLocaleInputComponent),
    },
  ],
})
export class PwLocaleInputComponent implements OnInit, ControlValueAccessor {
  #currentLocale: { name: string; value: string };

  /** 현재 편집중인 다국어 */
  @Input() set currentLocale(v: { name: string; value: string }) {
    this.#currentLocale = v;
    // this.findCurrentLocaleString();
  }

  get currentLocale(): { name: string; value: string } {
    // 현재 선택한 언어가 없다면 첫번째 언어를 기본으로 설정
    if (!this.#currentLocale) {
      [this.#currentLocale] = this.supportedLocales;
    }

    return this.#currentLocale;
  }

  @Output() currentLocaleChange = new EventEmitter<{
    name: string;
    value: string;
  }>();

  /** 지원하는 다국어 목록. 메뉴 오픈 상태에서 표시 */
  @Input() supportedLocales: { name: string; value: string }[];

  @Input() required = false;

  @Input() disabled = false;

  /** formControl 값 중 현애 선택한 다국어에 맞는 텍스트 */
  currentValue: any = {};

  onChange: any;

  onTouched: any;

  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: any) => {
      return !!control.errors && !control.disabled;
    },
  };

  #isChangeRequired = false;

  constructor(
    @Optional()
    @Inject(PW_SUPPORTED_LOCALES)
    private supportedLocaleInject: { name: string; value: string }[]
  ) {}

  ngOnInit(): void {
    if (!this.supportedLocales) {
      this.supportedLocales = this.supportedLocaleInject;
    }
  }

  /**
   * 입력란의 메뉴에서 변경할 다국어를 선택했을 때 동작.
   *
   * 현재 form 값 중 현재 선택한 다국어에 맞는 텍스트를 가져와서 입력란에 설정한다.
   *
   * 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
   * */
  onChangeLocale(locale: { name: string; value: string }): void {
    this.currentLocale = locale;

    // 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
    this.currentLocaleChange.emit(this.currentLocale);
  }

  /**
   * object 중 표시 언어에 맞는 값(object > ja 값 등) 가져오기
   */
  getLocaleModel(localeValue: string): string {
    if (!this.currentValue[localeValue]) {
      this.currentValue[localeValue] = '';
    }
    return this.currentValue[localeValue];
  }

  /**
   * 각 현지화 필드의 값이 변경되었을 때 동작. 다시 form value에 반영한다
   */
  onChangeCurrentValue(e, localeValue: string): void {
    this.currentValue[localeValue] = e;
    this.onChange(JSON.stringify(this.currentValue));
  }

  writeValue(obj: string): void {
    try {
      this.currentValue = JSON.parse(obj);
      if (this.currentValue == null) {
        this.currentValue = {};
      }
    } catch (e) {
      this.currentValue = {};
      this.supportedLocales.forEach((locale) => {
        this.currentValue[locale.value] = '';
      });
      this.currentValue[this.currentLocale.value] = obj;
      if (this.onChange) {
        this.onChange(JSON.stringify(this.currentValue));
      } else {
        this.#isChangeRequired = true;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this.#isChangeRequired) {
      this.onChange(JSON.stringify(this.currentValue));
      this.#isChangeRequired = false;
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
