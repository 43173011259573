import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormlyChipsComponent } from './template/formly-chips/formly-chips.component';
import { FormlyEditorComponent } from './template/formly-editor/formly-editor.component';
import { FormlyImageComponent } from './template/formly-image/formly-image.component';
import { FormlyInputComponent } from './template/formly-input/formly-input.component';
import { FormlyRatingComponent } from './template/formly-rating/formly-rating.component';
import { FormlyButtonWrapperComponent } from './wrapper/formly-button-wrapper/formly-button-wrapper.component';
import { FormlyImageWrapperComponent } from './wrapper/formly-image-wrapper/formly-image-wrapper.component';

@NgModule({
  declarations: [
    FormlyChipsComponent,
    FormlyEditorComponent,
    FormlyImageComponent,
    FormlyInputComponent,
    FormlyRatingComponent,
    FormlyButtonWrapperComponent,
    FormlyImageWrapperComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'button-wrapper', component: FormlyButtonWrapperComponent },
        { name: 'image-wrapper', component: FormlyImageWrapperComponent },
      ],
      types: [
        { name: 'chips', component: FormlyChipsComponent },
        { name: 'editor', component: FormlyEditorComponent },
        {
          name: 'image',
          component: FormlyImageComponent,
          wrappers: ['image-wrapper', 'button-wrapper', 'form-field'],
        },
        {
          name: 'input',
          component: FormlyInputComponent,
          wrappers: ['button-wrapper', 'form-field'],
        },
        // { name: 'rating', component: FormlyRatingComponent },
      ],
      validationMessages: [
        { name: 'required', message: '필수 항목입니다.' },
        { name: 'match', message: '일치하지 않습니다.' },
        { name: 'duplicated', message: '중복되는 정보가 존재합니다.' },
      ],
    }),

    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    NgxMaskModule,
  ],
  exports: [FormlyModule],
})
export class NgFormlyModule {}
