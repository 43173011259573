import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pw-footer',
  templateUrl: './pw-footer.component.html',
  styleUrls: ['./pw-footer.component.scss'],
  host: { class: 'pw-footer' },
  encapsulation: ViewEncapsulation.None,
})
export class PwFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
