import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 네트워크 연결 상태 조회하는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class NetStateService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 네트워크 연결 여부 조회
   */
  get isOnline(): Promise<boolean> {
    return this._flutterBridge.callHandler('net-state', 'connectivity');
  }

  /* startStream(listener: ((isOnline: boolean) => void)): void {
    window.addEventListener('netStateChanged', function (res: CustomEvent) {
      let isOnline = res.detail.isOnline;
      listener(isOnline);
    });
    this._flutterBridge.callHandler('net-state', 'stream-start');
  }

  pauseStream(): void {
    this._flutterBridge.callHandler('net-state', 'stream-pause');
  } */
}
