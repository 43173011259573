import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  PLATFORM_ID,
} from '@angular/core';

/**
 * 로컬스토리지 접두사 인젝션토큰
 * @default PW
 */
export const PW_STORAGE_PREFIX = new InjectionToken<string>('storagePrefix');

/**
 * 브라우저 로컬스토리지 제어 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * 로컬스토리지 접두사
   * @default PW.
   */
  prefix: string;

  constructor(
    @Optional()
    @Inject(PW_STORAGE_PREFIX)
    _storagePrefix: string,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.prefix = `${_storagePrefix || 'PW'}.`;
  }

  /**
   * 로컬스토리지 에서 값을 가져온다. 값이 없을 경우 nvl을 스토리지에 저장하지 않고 그대로 반환한다
   */
  get(key: string, nvl: any = null): any {
    if (isPlatformServer(this.platformId)) {
      return nvl;
    }

    const v = localStorage.getItem(this.prefix + key);
    let parsed: any = nvl;

    try {
      // null이거나 undefined이면 nvl 저장
      parsed = JSON.parse(v) ?? nvl;
    } catch (error) {
      // 오류시 parsed는 여전히 nvl 이기때문에 로그만 찍고 계속 진행
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (parsed === nvl) {
      // nvl이면 삭제
      localStorage.removeItem(this.prefix + key);
    }

    return parsed;
  }

  /**
   * 로컬스토리지에 키/밸류 JSON 문자열 형태로 저장
   */
  set(key: string, value: any): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (value === null || value === 'null') {
      localStorage.removeItem(this.prefix + key);
    } else {
      localStorage.setItem(this.prefix + key, JSON.stringify(value));
    }
  }

  /**
   * 로컬스토리지에서 해당 키를 완전 삭제
   */
  delete(key: string): void {
    if (localStorage.getItem(this.prefix + key) != null) {
      localStorage.removeItem(this.prefix + key);
    }
  }
}
