/**
 * 페이지 단위로 처리하는 목록 데이터.
 *
 * 현재 페이지의 목록만 있을 수 있고, 첫 페이지부터 누적시킬 수(infinite scroll 참고) 있음
 *
 * Page.page 어감이 이상하여, 종전 사용하던 형식과 다르게 page 상태 관련 값은 상위로 이동함.
 */
export interface Page<T = any> {
  /** 목록 */
  content: Array<T>;

  /** 목록 내 현재페이지의 요소 개수 */
  numberOfElements: number;

  /** 목록 내 현재 페이지의 크기 */
  size: number;

  /** 현재 페이지 번호 */
  number: number;

  /** 총 페이지 개수. 요소가 전혀 없다면 0 */
  totalPages: number;

  /** 총 요소 개수 */
  totalElements: number;
}
