import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';

const matDialogConfig: MatDialogConfig<any> = {
  hasBackdrop: true,
  /* width: '80vw',
  panelClass: 'custom-mat-dialog',
  backdropClass: 'custom-mat-dialog-backdrop', */
};

/**
 * 머터리얼 컴포넌트를 이용한 대화창 서비스
 * @see `DialogModule`
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private _dialog: MatDialog) {}

  /**
   * 확인 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  alert(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>
  ): Observable<any>;

  /**
   * 확인 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   * @param close 사용자 지정 닫기 버튼 문구
   */
  alert(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>,
    close?: string
  ): Observable<any>;

  alert(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>,
    close?: string
  ): Observable<any> {
    const dialogRef = this._dialog.open(AlertComponent, {
      ...matDialogConfig,
      ...config,
      data: {
        body,
        header,
        close,
      },
    });
    return dialogRef.afterClosed();
  }

  /**
   * 예/아니오 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  confirm(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>
  ): Observable<any>;

  /**
   * 예/아니오 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   * @param yes 사용자 지정 확인 버튼 문구
   * @param no 사용자 지정 취소 버튼 문구
   */
  confirm(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>,
    yes?: string,
    no?: string
  ): Observable<any>;

  confirm(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>,
    yes?: string,
    no?: string
  ): Observable<any> {
    // if (!header) {
    //   header = '안내';
    // }
    const dialogRef = this._dialog.open(ConfirmComponent, {
      ...matDialogConfig,
      ...config,
      disableClose: true,
      data: {
        body,
        header,
        yes,
        no,
      },
    });
    return dialogRef.afterClosed();
  }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  // snackbar(
  //   message: string,
  //   action: string = null,
  //   config: MatSnackBarConfig = { duration: 2000 }
  // ): void {
  //   this._snackBar.open(message, action, config);
  // }
}
