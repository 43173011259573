<div
  class="viewinsets"
  [ngStyle]="{
    'margin-top.px': (systemPadding$ | async)?.top,
    'margin-left.px': (systemPadding$ | async)?.left,
    'margin-bottom.px': (systemPadding$ | async)?.bottom,
    'margin-right.px': (systemPadding$ | async)?.right
  }"
>
  <ng-content ></ng-content>
</div>
