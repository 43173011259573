import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { PW_SUPPORTED_LOCALES } from '../../pw-locale';

/**
 * 현지화 필드(DB 에 json 형식, 각 키는 언어 코드로 구성하는 데이터)
 *
 * 여려줄 지원 Textarea 사용
 */
@Component({
  selector: 'pw-locale-textarea',
  templateUrl: './pw-locale-textarea.component.html',
  styleUrls: ['./pw-locale-textarea.component.scss'],
})
export class PwLocaleTextareaComponent implements OnInit {
  /** 다국어 json 형태로 저장할 formControl */
  @Input() control: FormControl;

  #currentLocale: { name: string; value: string };

  /** 현재 편집중인 다국어 */
  @Input() set currentLocale(v: { name: string; value: string }) {
    this.#currentLocale = v;
    // this.findCurrentLocaleString();
  }

  get currentLocale() {
    // 현재 선택한 언어가 없다면 첫번째 언어를 기본으로 설정
    if (!this.#currentLocale) {
      [this.#currentLocale] = this.supportedLocales;
    }

    return this.#currentLocale;
  }

  @Output() currentLocaleChange = new EventEmitter<{
    name: string;
    value: string;
  }>();

  /** 지원하는 다국어 목록. 메뉴 오픈 상태에서 표시 */
  @Input() supportedLocales: { name: string; value: string }[];

  @Input() required = false;

  @Input() disabled = false;

  @Input() rows = 3;

  /** formControl 값 중 현애 선택한 다국어에 맞는 텍스트 */
  currentValue: any = {};

  constructor(
    @Optional()
    @Inject(PW_SUPPORTED_LOCALES)
    private supportedLocaleInject: { name: string; value: string }[]
  ) {}

  /**
   * 컴포넌트 초기화 시
   *
   * formControl 값이 없다면 초기화. 현재 언어에 맞는 String을 currentValue 에 설정
   * */
  ngOnInit(): void {
    if (!this.supportedLocales) {
      this.supportedLocales = this.supportedLocaleInject;
    }
    if (!this.control) {
      this.control = new FormControl(JSON.stringify(this.currentValue));
    } else if (!this.control.value) {
      this.control.patchValue(JSON.stringify(this.currentValue));
    } else {
      this.currentValue = JSON.parse(this.control.value);
    }
    // this.findCurrentLocaleString();
  }

  /**
   * 입력란의 메뉴에서 변경할 다국어를 선택했을 때 동작.
   *
   * 현재 form 값 중 현재 선택한 다국어에 맞는 텍스트를 가져와서 입력란에 설정한다.
   *
   * 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
   * */
  onChangeLocale(locale: { name: string; value: string }): void {
    this.currentLocale = locale;

    // 상위로 전파하여 다른 입력란의 다국어 설정도 변경해야 한다
    this.currentLocaleChange.emit(this.currentLocale);
  }

  /**
   * object 중 표시 언어에 맞는 값(object > ja 값 등) 가져오기
   */
  getLocaleModel(localeValue: string): string {
    if (!this.currentValue[localeValue]) {
      this.currentValue[localeValue] = '';
    }
    return this.currentValue[localeValue];
  }

  /**
   * 각 현지화 필드의 값이 변경되었을 때 동작. 다시 form value에 반영한다
   */
  onChangeCurrentValue(e, localeValue: string): void {
    this.currentValue[localeValue] = e;
    this.control.setValue(JSON.stringify(this.currentValue));
  }
}
