import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwLocaleInputModule } from './pw-locale-input/pw-locale-input.module';
import { PwLocaleTextareaModule } from './pw-locale-textarea/pw-locale-textarea.module';
import { PwLocaleTuiEditorModule } from './pw-locale-tui-editor/pw-locale-tui-editor.module';

@NgModule({
  imports: [
    CommonModule,
    PwLocaleInputModule,
    PwLocaleTextareaModule,
    PwLocaleTuiEditorModule,
  ],
  exports: [
    PwLocaleInputModule,
    PwLocaleTextareaModule,
    PwLocaleTuiEditorModule,
  ],
})
export class PwLocaleCvaModule {}
