import {
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractAuthService } from 'projects/pw-lib/src/public-api';
import {
  catchError,
  finalize,
  mergeMap,
  Observable,
  of,
  shareReplay,
  throwError,
} from 'rxjs';

type HttpUpdate = {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  body?: any | null;
  method?: string;
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
  setParams?: {
    [param: string]: string;
  };
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private httpRequestUpdate: (token: any) => HttpUpdate = (token) => {
    return {
      setHeaders: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
  };

  private authRequest$: Observable<any>;

  constructor(
    private authService: AbstractAuthService,
    private authCheckUrl: string,
    injectedHttpRequestUpdate?: (token: any) => HttpUpdate
  ) {
    this.httpRequestUpdate =
      injectedHttpRequestUpdate || this.httpRequestUpdate;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 토큰 요청 url이 포함되지 않도록 주의
    if (request.url.indexOf(this.authCheckUrl) !== 0) {
      // 주입받은 url로 시작하지 않으면
      return next.handle(request);
    }

    const auth$ = this.authService.auth
      ? of(this.authService.auth)
      : this.authService.getNewAuth();

    return auth$.pipe(
      mergeMap((token) => {
        return token
          ? this.getRequestWithToken(request, next, token)
          : next.handle(request);
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.error === 'invalid_token') {
          this.authRequest$ =
            this.authRequest$ || this.getRefreshRequest(request, next);

          return this.authRequest$;
        }

        return throwError(() => httpErrorResponse);
      }),
      shareReplay()
    );
  }

  private getRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: any
  ): Observable<HttpEvent<any>> {
    const authAddedRequest: HttpRequest<any> = request.clone(
      this.httpRequestUpdate(token)
    );

    return next.handle(authAddedRequest);
  }

  private getRefreshRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getRefreshAuth().pipe(
      mergeMap((token) => {
        return this.getRequestWithToken(request, next, token);
      }),
      catchError((refreshError: HttpErrorResponse) => {
        if (refreshError.error.error === 'invalid_token') {
          this.authService.clearAuth();
        }

        return throwError(() => refreshError);
      }),
      finalize(() => {
        this.authRequest$ = null;
      }),
      shareReplay()
    );
  }
}
