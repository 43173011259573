import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 상태바 관련 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 상태바 색상 설정
   *
   * Android 전용
   *
   * @param colorHex 6(RGB) 또는 8(ARGB)자리 색상 코드
   */
  setStatusBarColor(colorHex: string): void {
    this._flutterBridge.callHandler('statusBar', 'color', colorHex);
  }

  /**
   * 상태바 아이콘 밝기 설정
   *
   * @param brightness light | dark
   */
  setStatusBarBrightness(brightness: 'light' | 'dark'): void {
    this._flutterBridge.callHandler('statusBar', 'brightness', brightness);
  }
}
