import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 기기 정보 조회하는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 기기의 UDID 조회
   */
  get udid(): Promise<string> {
    return this._flutterBridge.callHandler('udid');
  }
}
