import { Component, Input, OnInit } from '@angular/core';

/**
 * 현지화 뷰어(언어별 번역 표시)의 언어 아이콘
 */
@Component({
  selector: 'pw-locale-icon',
  templateUrl: './pw-locale-icon.component.html',
  styleUrls: ['./pw-locale-icon.component.scss'],
})
export class PwLocaleIconComponent implements OnInit {
  @Input()
  locale: { name: string; value: string };

  get iconText(): string {
    // return this.locale.name.substring(0, 1);
    return this.locale.name;
  }

  constructor() {}

  ngOnInit(): void {}
}
