<div class="locale-row" *ngFor="let locale of supportedLocales">
  <div class="locale-txt">
    <pw-locale-icon
      [locale]="locale"
      (click)="input.focus()"
      matRipple
      [matRippleDisabled]="disabled"
    >
    </pw-locale-icon>
    <mat-form-field class="locale-field-item">
      <input
        matInput
        #input
        class="locale-input"
        type="text"
        [disabled]="disabled"
        [required]="required"
        [errorStateMatcher]="errorStateMatcher"
        [ngModel]="getLocaleModel(locale.value)"
        (ngModelChange)="onChangeCurrentValue($event, locale.value)"
        (blur)="onTouched()"
      />
    </mat-form-field>
  </div>
</div>
