import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwHtmlViewerComponent } from './pw-html-viewer.component';



@NgModule({
  declarations: [PwHtmlViewerComponent],
  imports: [
    CommonModule
  ],
  exports: [PwHtmlViewerComponent]
})
export class PwHtmlViewerModule { }
