import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwContentComponent } from './pw-content/pw-content.component';
import { PwFooterComponent } from './pw-footer/pw-footer.component';
import { PwHeaderComponent } from './pw-header/pw-header.component';

const components = [PwHeaderComponent, PwContentComponent, PwFooterComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
})
export class PwLayoutModule {}
