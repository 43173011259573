import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwInputDirective } from './input/pw-input.directive';

const directiveList = [PwInputDirective];

@NgModule({
  declarations: directiveList,
  imports: [CommonModule],
  exports: directiveList,
})
export class PwDirectiveModule {}
