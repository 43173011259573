import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pwLocale',
})
export class PwLocalePipe implements PipeTransform {
  transform(value: any, lang = 'ko', ...args: unknown[]): string {
    // 값이 없으면 - 반환
    if (value === undefined || value === null) {
      // 없으면 반환
      return '-';
    }

    // string type 이면 JSON 변환 시도하고, JSON 타입이 아니면 string 그대로 return
    if (typeof value === 'string') {
      try {
        const json = this.toJson(value);
        if (json) {
          // 해당 언어의 번역이 없으면, 다른 언어의 번역을 반환
          if (!json[lang]) {
            return this.getFallback(json);
          }
          return json[lang];
        }
      } catch (e) {
        //
      }
      return value;
    }

    return JSON.stringify(value);
  }

  /**
   * 유효한 json 일 때만 JSON 객체 반환
   */
  toJson(str: any): any {
    if (!str) {
      throw new Error('value is empty');
    }
    const j = JSON.parse(str);
    if (j && typeof j === 'object' && j !== null) {
      return j;
    }
    throw new Error('value is not json');
  }

  /**
   * 언어 상관없이 값이 비어있지 않은 번역을 반환
   *
   * 다른 언어의 번역도 없으면, 공백 반환
   */
  private getFallback(value: any): string {
    return (
      Object.values<string>(value).find((v) => v != null && v.length) || ''
    );
  }
}
