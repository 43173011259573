import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

/**
 * paywith img 확장 컴포넌트
 *
 * 외부 이미지가 아닌 자체 이미지라면
 */
@Component({
  selector: 'pw-img',
  templateUrl: './pw-img.component.html',
  styleUrls: ['./pw-img.component.scss'],
})
export class PwImgComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * 원본 이미지 경로
   *
   * 이미지 경로에 /origin/ 이 들어가면 원본 이미지로 인식하고, size 값이 있으면 다른 이미지 크기를 보여주기 위해 /origin/ 을 대체한다
   */
  @Input()
  src: string;

  /**
   * 변경할 이미지 사이즈
   *
   * 원본 이미지 경로에 /origin/ 이 있을 때에만 유효하며, 없을 때는 원본 이미지를 보여준다
   */
  @Input()
  size: 's' | 'm' | 'l' | 'o' = 'o';

  /** 마우스 올렸을 때 강조 이벤트(이미지 확대) */
  @Input()
  hoverZoom = true;

  /**
   * size 변수를 계산하여 로드할 이미 경로
   *
   * size 애 따라 이미지 경로의 /origin/ 을 /w_200/ /w_400/ /w_600/ 으로 변경한다
   */
  imgSrc: string | null;

  /**
   * 재 요청 시도 중에 이미지 깨진 아이콘 보여주지 않기 위해 사용.
   *
   * FIXME: 현재 정상적으로 작동하지 않아서 깨진 아이콘 보여주고 있음
   */
  isRetrying = false;

  /**
   * 이미지 로드 실패 시 재 요청 시도하는지 상태 관리. destory 되면 삭제
   */
  #interval;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // 실행 중인 interval 제거
    if (this.#interval) {
      clearInterval(this.#interval);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // 최초 이미지 업로드 후 갱신이 제대로 되지 않아서 변경 이벤트 처리 직접 작성
    if (changes['src'].previousValue != changes['src'].currentValue) {
      this.setImgSrc();
    }
  }

  /**
   * 원본 이미지 경로를 size 옵션에 맞춰서 리사이징 한 이미지로 변경한다
   */
  setImgSrc(): void {
    this.imgSrc = '';
    // 이미지가 없다면 처리 정지
    const src = this.src;
    if (!src) return;

    // src 확장자가 .png, .jpg, .jpeg 가 아니면 리사이징 하지 않았으므로 원본 이미지를 보여준다
    const ext = src.split('.').pop();
    if (ext != 'png' && ext != 'jpg' && ext != 'jpeg') {
      this.imgSrc = src;
      return;
    }

    // /origin/ 이 있으면 페이위드에서 S3에 올려 리사이징 한 이미지로 간주하고 리사이징 이미지 경로로 대체한다
    const origin = src.indexOf('/origin/');
    if (origin === -1) {
      this.imgSrc = src;
      return;
    }
    let size = '';
    switch (this.size) {
      case 'o': // 원본은 주소 교체할 필요가 없으므로 그대로 리턴
        this.imgSrc = src;
        return;
      case 's':
        size = '200';
        break;
      case 'm':
        size = '400';
        break;
      case 'l':
        size = '600';
        break;
    }
    this.imgSrc = `${src.substring(0, origin)}/w_${size}/${src.substring(
      origin + 8
    )}`;
  }

  /**
   * 이미지 로드 중 오류 핸들링
   *
   * 기존 imgSrc를 없애고 다시 설정하여 event.target(img).src 에 적용한다
   */
  onError(e: Event): void {
    this.imgSrc = '';
    this.#interval = setTimeout(() => {
      this.setImgSrc();
      e.target['src'] = this.imgSrc;
    }, 1000);
  }
}
