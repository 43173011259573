/* eslint-disable no-console */
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

/**
 * http 로깅 인터셉터
 */
@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private logNumber = 0;

  constructor(private isProd?: boolean) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isProd) {
      return next.handle(req);
    }

    const number = this.getLogNumber();
    const startTime = Date.now();
    const limit = 1000;
    let status = '실패';
    let res: any = null;

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          status = '성공';
          res = event.body;
        }
      }),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message = `${status}, ${new Date().toLocaleTimeString()}, ${elapsedTime} ms`;

        console.group(`%c ${req.method} ${number}`, this.getColor(req.method));
        console.log('URL', req.urlWithParams);
        if (elapsedTime > limit) {
          console.debug('MSG', message, `(${limit}ms 초과)`);
        } else {
          console.log('MSG', message);
        }
        console.log('REQ', this.deepClone(req));
        console.log('RES', this.deepClone(res));
        console.groupEnd();
      })
    );
  }

  /**
   * http 로그 순서 반환
   */
  private getLogNumber(): string {
    if (this.logNumber > 99) {
      this.logNumber = 0;
    }

    const number = (this.logNumber < 10 ? '0' : '') + this.logNumber;
    this.logNumber += 1;

    return number;
  }

  /**
   * http 메소드별 로그 색상 문자열 반환
   * @param method http 메소드
   * @returns `console.log` 색상 문자열
   */
  private getColor(method: string): string {
    switch (method) {
      case 'GET':
        return 'color: #fff; background-color: #2196f3';
      case 'POST':
        return 'color: #fff; background-color: #4caf50';
      case 'PUT':
        return 'color: #fff; background-color: #9c27b0';
      case 'PATCH':
        return 'color: #fff; background-color: #4d2f40';
      case 'DELETE':
        return 'color: #fff; background-color: #f44336';
      default:
        return 'color: #000; background-color: #fff';
    }
  }

  /**
   * 많이 반복하는 logger 특성상, JSON 객체는 성능 떨어지므로 직접 구현
   */
  private deepClone(o: any): any {
    if (o === null || typeof o !== 'object') {
      return o;
    }

    const result = Array.isArray(o) ? [] : {};

    Object.keys(o).forEach((key) => {
      result[key] = this.deepClone(o[key]);
    });

    return result;
  }
}
