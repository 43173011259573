import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwLocaleInputModule } from './pw-locale-input/pw-locale-input.module';
import { PwLocaleTextareaModule } from './pw-locale-textarea/pw-locale-textarea.module';
import { PwLocaleTuiEditorModule } from './pw-locale-tui-editor/pw-locale-tui-editor.module';
import { PwLocaleViewerModule } from './pw-locale-viewer/pw-locale-viewer.module';
import { PwLocalePipe } from './pw-locale.pipe';

@NgModule({
  declarations: [PwLocalePipe],
  imports: [
    CommonModule,
    PwLocaleInputModule,
    PwLocaleTextareaModule,
    PwLocaleViewerModule,
    PwLocaleTuiEditorModule,
  ],
  exports: [
    PwLocaleInputModule,
    PwLocaleViewerModule,
    PwLocaleTextareaModule,
    PwLocaleTuiEditorModule,
    PwLocalePipe,
  ],
})
export class PwLocaleModule {}
