<div id="layout-view-{{ depth }}" [class.under-layer]="!shouldDisplay">
  <ng-content></ng-content>
</div>

<div
  id="layout-animation-{{ depth + 1 }}"
  [@routeAnimations]="
    shouldAnimate && pwLayoutService.animationDirection$ | async
  "
  (@routeAnimations.done)="onAnimationsDone($event)"
>
  <router-outlet></router-outlet>
</div>
