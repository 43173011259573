import { Component } from '@angular/core';
import { MatBadgePosition } from '@angular/material/badge';
import { ThemePalette } from '@angular/material/core';
import { FieldWrapper } from '@ngx-formly/core';

export interface FormlyButtonWrapper {
  label: string;
  color?: ThemePalette;
  disabled?: () => boolean;
  canShow?: () => boolean;
  clickButton: () => any;
  matBadge?: () => string;
  matBadgePosition?: () => MatBadgePosition;
  matBadgeColor?: () => ThemePalette;
}

@Component({
  selector: 'app-formly-button-wrapper',
  templateUrl: './formly-button-wrapper.component.html',
  styleUrls: ['./formly-button-wrapper.component.scss'],
})
export class FormlyButtonWrapperComponent extends FieldWrapper {
  public get buttons(): FormlyButtonWrapper[] {
    return this.to.buttons || [];
  }

  public get hasButton(): boolean {
    return this.buttons.length > 0;
  }

  public onClickButton(index: number): void {
    this.to.buttons[index].clickButton();
  }
}
