import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

/**
 * Flutter 브릿지 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class FlutterBridgeService {
  private _init: Observable<boolean>;

  private _hasInit = false;

  /**
   * 브릿지 서비스 초기화
   *
   * 초기화 시작 후 3초 이내로 'flutterInAppWebViewPlatformReady' 이벤트가
   * 발생하지 않으면 false, 시간 상관없이 이벤트가 발생했다면 true를 반환.
   *
   * @returns 초기화 완료 여부
   */
  init(): Observable<boolean> {
    if (this._init) {
      return this._init;
    }
    const initSubject = new ReplaySubject<boolean>(1);
    console.log('Flutter Bridge Init');
    if (this._hasInit) {
      initSubject.next(true);
      initSubject.complete();
    }
    const timeoutId = setTimeout(() => {
      console.log('Flutter Bridge Init timeout');
      initSubject.next(false);
    }, 3000);
    window.addEventListener('flutterInAppWebViewPlatformReady', () => {
      console.log('Flutter Bridge Init completed');
      clearTimeout(timeoutId);
      this._hasInit = true;
      initSubject.next(true);
      initSubject.complete();
    });
    this._init = initSubject.asObservable();
    return this._init;
  }

  /**
   * Flutter에 구현 된 Handler 호출.
   *
   * 초기화가 완료되지 않았을 경우, 호출을 무시한다. (null 반환)
   *
   * @param name Flutter에 구현 된 Handler 이름
   * @param args Handler에 보낼 매개변수
   * @returns 응답 값. 초기화되지 않았거나 대응 Handler가 없으면 null.
   */
  callHandler<T>(name: string, ...args: any[]): Promise<T> {
    return this.init()
      .pipe(
        take(1),
        mergeMap(async (isInit) => {
          if (isInit) {
            let result: any;
            if (window.flutter_inappwebview.callHandler) {
              result = await window.flutter_inappwebview.callHandler(
                name,
                ...args
              );
            } else {
              result = await window.flutter_inappwebview._callHandler(
                name,
                setTimeout(() => null),
                JSON.stringify([...args])
              );
            }

            try {
              const data = JSON.parse(result);
              if (data?.error) {
                throw data;
              }
              return data;
            } catch (e) {
              if (e?.error) {
                throw Error(`${result.error}\n${result.stackTrace}`);
              }
              if (result?.error) {
                throw Error(`${result.error}\n${result.stackTrace}`);
              }

              return result;
            }
          } else {
            return null;
          }
        })
      )
      .toPromise();
  }
}

declare global {
  interface Window {
    /**
     * Flutter InAppWebView Handler
     */
    // eslint-disable-next-line camelcase
    flutter_inappwebview: {
      callHandler: (name: string, ...args: any[]) => Promise<any>;
      _callHandler: (name: string, ...args: any[]) => Promise<any>;
    };
  }
}
