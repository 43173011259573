import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IFileResponse {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(protected httpClient: HttpClient) {}

  upload(file: File): Observable<IFileResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const headers = { Accept: 'application/json' };

    return this.httpClient.post<IFileResponse>(
      `${environment.fileServerUrl}`,
      formData,
      {
        headers,
      }
    );
  }
}
