import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Table 정의서 String 배열 타입 Pipe. 번역 키 그룹(REPO.STRING) 을 붙여 번역하건, 원본 문자열 그대로 반환
 */
@Pipe({
  name: 'stringList',
})
export class StringListPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param value: string[]
   * @param options: { translate: boolean } = { translate: false } 번역 여부.
   */
  transform(
    value: string[],
    options: { translate: boolean } = { translate: false }
  ): Observable<string[]> {
    // 값이 없으면 - 반환
    if (value === undefined || value === null) {
      // 없으면 반환
      return of(['']);
    }

    if (options.translate && value.length > 0) {
      // translate.stream(value: string[]) 은 {'key1': 'value1', 'key2': 'value2'} 형태로 반환하므로, 변환하여 return
      return this.translateService
        .stream(value.map((s) => `REPO.STRING.${camelCase(s)}`))
        .pipe(
          map((obj) => {
            const result: string[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
              // eslint-disable-next-line no-prototype-builtins
              if (obj.hasOwnProperty(key)) {
                result.push(obj[key]);
              }
            }
            return result;
          })
        );
    }

    return of(value);
  }
}
