import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService, SystemPadding } from '../../services/bridge/app.service';

/**
 * 공통 SafeArea
 * 시스템의 상태바, 하단바 크기 만큼 padding 처리
 * @deprecated `pw-header`, `pw-content`, `pw-footer`에서 제어하도록 변경
 */
@Component({
  selector: 'pw-safearea',
  templateUrl: './pw-safearea.component.html',
  styleUrls: ['./pw-safearea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PwSafeAreaComponent implements OnInit {
  /**
   * 시스템 상단 padding 적용
   */
  @Input() useSystemTopPadding = true;

  /**
   * 상단 추가 padding
   */
  @Input() additionalTopPadding = 0;

  /**
   * 시스템 좌측 padding 적용
   */
  @Input() useSystemLeftPadding = true;

  /**
   * 좌측 추가 padding
   */
  @Input() addtionalLeftPadding = 0;

  /**
   * 시스템 하단 padding 적용
   */
  @Input() useSystemBottomPadding = true;

  /**
   * 하단 추가 padding
   */
  @Input() addtionalBottomPadding = 0;

  /**
   * 시스템 우측 padding 적용
   */
  @Input() useSystemRightPadding = true;

  /**
   * 우측 추가 padding
   */
  @Input() addtionalRightPadding = 0;

  /**
   * SafeArea 높이
   */
  @Input() height: string;

  /**
   * 배경 색상
   */
  @Input() backgroundColor: string;

  /**
   * 시스템 padding
   */
  systemPadding$: Promise<SystemPadding>;

  constructor(private _appService: AppService) {}

  ngOnInit(): void {
    this.systemPadding$ = this._appService.getSystemPadding();
  }
}
