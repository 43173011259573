import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[pwCoachMark]',
})
export class PwCoachMarkDirective
  implements OnInit, OnChanges, OnDestroy, AfterContentChecked
{
  /**
   * 코치마크 기본 상태.
   *
   * text : 코치마크 내용
   * isOpen : 코치마크 열림 여부
   */
  @Input()
  pwCoachMark: { text: string; hidden?: boolean };

  /**
   * 컨테이너
   */
  private coachMarkContainer: HTMLElement;

  /**
   * 코치마크 요소
   */
  private coachMarkElement: HTMLElement;

  /**
   * 강조 표시를 위해 복제한 요소
   */
  private clonedTargetElement: HTMLElement;

  /**
   * 코치마크 크기 변경 감지
   */
  private coachMarkResizeObserver: ResizeObserver;

  /** 코치마크 비활성 조건. 아예 그리지 않는다 */
  get disabled(): boolean {
    return !this.pwCoachMark || this.pwCoachMark.hidden;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.createCoachMarkContainer();
  }

  ngOnInit(): void {
    if (this.disabled) return;
    // html 문서에서 .coach-mark-container 를 찾는다.
    this.coachMarkContainer = this.document.querySelector(
      '.coach-mark-container'
    );
    if (this.pwCoachMark.hidden === undefined) {
      this.pwCoachMark.hidden = false;
    }

    this.drawCoachmark();
  }

  ngAfterContentChecked(): void {
    if (this.disabled) return;

    if (
      this.coachMarkContainer &&
      this.isContainerVisible() &&
      this.coachMarkElement
    ) {
      this.drawCoachmark();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) return;

    if (changes.pwCoachMark) {
      if (this.pwCoachMark.hidden === undefined) {
        this.pwCoachMark.hidden = false;
      }
      if (this.coachMarkElement) {
        this.coachMarkElement.innerHTML = this.pwCoachMark.text;
      }
    }
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    if (this.disabled) return;

    if (
      this.coachMarkContainer &&
      this.isContainerVisible() &&
      this.coachMarkElement
    ) {
      // 스크롤 발생 시 위치 이동
      this.drawCoachmark();
    }
  }

  /**
   * 코치마크 위치 설정
   *
   * 코치마크가 없으면 새로 생성 후 위치 설정
   */
  drawCoachmark(): void {
    if (this.pwCoachMark.hidden) {
      this.renderer.removeChild(this.coachMarkContainer, this.coachMarkElement);
      this.coachMarkElement = null;
      return;
    }

    // coachMarkText는 요소에서 조금 떨어져 보이므로, 화살표를 절대위치로 표시하여 가리킨다
    const coachMarkArrowHeight = 14;
    const coachMarkArrowWidth = 14;

    // coachMark 최초 생성
    if (!this.coachMarkElement) {
      this.coachMarkElement = this.document.createElement('div');
      this.coachMarkElement.style.position = 'absolute';
      this.coachMarkElement.style.maxWidth = '40vw';
      this.coachMarkElement.style.width = 'fit-content';
      this.coachMarkElement.style.height = 'fit-content';
      this.coachMarkElement.style.display = 'inline';
      this.coachMarkElement.style.padding = '10px';
      this.coachMarkElement.style.fontSize = '24px';
      this.coachMarkElement.style.color = 'white';
      this.coachMarkElement.style.opacity = '0.8';

      this.coachMarkElement.innerHTML = this.pwCoachMark.text;

      this.coachMarkResizeObserver = new ResizeObserver(() => {
        // 크기 바뀌면 위치 재설정
        this.drawCoachmark();
      });
      this.coachMarkResizeObserver.observe(this.coachMarkElement);

      this.renderer.appendChild(this.coachMarkContainer, this.coachMarkElement);
    }

    const targetElement = this.elementRef.nativeElement;
    const targetElementPosition = targetElement.getBoundingClientRect();

    // targetElement 의 높이 또는 너비가 없거나 숨김 상태일때, coachMark 를 숨긴다.
    if (
      targetElement.style.display === 'none' ||
      !targetElementPosition.width ||
      !targetElementPosition.height
    ) {
      this.renderer.setStyle(this.coachMarkElement, 'display', 'none');
      return;
    }
    this.renderer.removeStyle(this.coachMarkElement, 'display');

    // 코치마크가 타겟의 어디에 위치하는 지에 따라 화살표(배경이미지) 위치를 다르게 함

    /** 코치마크가 타겟 위쪽에 위치하는지 */
    let isCoachMarkTop = false;

    /** 코치마크가 타겟 왼쪽에 위치하는지 */
    let isCoachMarkLeft = false;

    /** 코치마크 텍스트 기준으로 화살표가 위치하는지 */
    let isArrowTop = false;

    /** 코치마크 텍스트 기준으로 화살표가 위치하는지 */
    let isArrowLeft = false;

    // 코치마크 배치 위치 확인

    // 코치마크 배치 기준 위치
    const targetElementLeft = targetElementPosition.left;
    const targetElementRight = targetElementPosition.right;
    const coachMarkPosX = (targetElementLeft + targetElementRight) / 2;
    const coachMarkPosY =
      (targetElementPosition.top + targetElementPosition.bottom) / 2;
    // /코치마크 배치 기준 위치

    const { width: coachMarkWidth, height: coachMarkHeight } =
      this.coachMarkElement.getBoundingClientRect();

    // 코치마크가 타겟 위, 아래 위치하는지
    if (document.body.scrollHeight < coachMarkPosY + coachMarkHeight) {
      // 위쪽에 배치할 때 coachmark의 너비 때문에 overflow가 되면 왼쪽으로 배치한다
      isCoachMarkTop = true;
      isArrowTop = !isCoachMarkTop;
    } else {
      isCoachMarkTop = false;
      isArrowTop = !isCoachMarkTop;
    }

    // 코치마크가 타겟 왼쪽, 오른쪽에 위치하는지
    if (document.body.scrollWidth < coachMarkPosX + coachMarkWidth) {
      // 오른쪽에 배치할 때 coachmark의 너비 때문에 overflow가 되면 왼쪽으로 배치한다
      isCoachMarkLeft = true;
      isArrowLeft = !isCoachMarkLeft;
    } else {
      isCoachMarkLeft = false;
      isArrowLeft = !isCoachMarkLeft;
    }
    // /코치마크 배치 위치 확인

    // 코치마크 배치
    if (isCoachMarkTop) {
      this.coachMarkElement.style.top = `${
        coachMarkPosY - coachMarkHeight - coachMarkArrowHeight - 10
      }px`;
    } else {
      this.coachMarkElement.style.top = `${
        coachMarkPosY + coachMarkArrowHeight + 10
      }px`;
    }
    if (isCoachMarkLeft) {
      this.coachMarkElement.style.left = `${
        coachMarkPosX - coachMarkWidth - coachMarkArrowWidth - 10
      }px`;
    } else {
      this.coachMarkElement.style.left = `${
        coachMarkPosX + coachMarkArrowWidth + 10
      }px`;
    }
    // /코치마크 배치

    this.drawCoachMarkArrow(
      isArrowTop,
      isArrowLeft,
      coachMarkArrowHeight,
      coachMarkArrowWidth
    );
  }

  ngOnDestroy(): void {
    if (this.disabled) return;

    this.coachMarkResizeObserver?.disconnect();

    /** destroy 시 null 오류 대응 */
    if (this.coachMarkContainer && this.coachMarkElement) {
      this.renderer?.removeChild(
        this.coachMarkContainer,
        this.coachMarkElement
      );
    }
  }

  private isContainerVisible(): boolean {
    return this.coachMarkContainer.style.display !== 'none';
  }

  /**
   * 코치마크의 화살표 부분 추가
   *
   * @param isPositionTop 화살표가 위에 위치하는지(아니면 아래 위치)
   * @param isPositionLeft 화살표가 왼쪽에 위치하는지(아니면 오른쪽 위치)
   * @param arrowHeight 화살표 높이
   * @param arrowWidth 화살표 너비
   * */
  drawCoachMarkArrow(
    isPositionTop: boolean,
    isPositionLeft: boolean,
    arrowHeight: number,
    arrowWidth: number
  ): void {
    // 코치마크 요소에 화살표 요소가 이미 있는지 확인하고 없을 때는 새로 생성
    let coachMarkArrow: HTMLImageElement =
      this.coachMarkElement.querySelector('.coach-mark-arrow');
    if (!coachMarkArrow) {
      // 없으므로 화살표 새로 생성
      coachMarkArrow = this.renderer.createElement('img');
      coachMarkArrow.style.position = 'absolute';
      coachMarkArrow.src = 'assets/imgs/coach-mark-arrow.png';
      coachMarkArrow.style.height = `${arrowHeight}px`;
      coachMarkArrow.style.width = `${arrowWidth}px`;
      this.renderer.addClass(coachMarkArrow, 'coach-mark-arrow');
      this.renderer.appendChild(this.coachMarkElement, coachMarkArrow);
    }

    // 코치마크 화살표를 코치마크 요소 어디에 위치시킬지
    if (isPositionTop) {
      coachMarkArrow.style.top = `${-1 * arrowHeight}px`;
      coachMarkArrow.style.bottom = null;
    } else {
      coachMarkArrow.style.top = null;
      coachMarkArrow.style.bottom = `${-1 * arrowHeight}px`;
    }
    if (isPositionLeft) {
      coachMarkArrow.style.left = `${-1 * arrowWidth}px`;
      coachMarkArrow.style.right = null;
    } else {
      coachMarkArrow.style.left = null;
      coachMarkArrow.style.right = `${-1 * arrowWidth}px`;
    }

    /**  기본 좌상 가리키는 화살표를 어떻게 돌릴지 */
    let rotate = 0;
    if (isPositionTop) {
      // 위 가리키는 화살표(기본 이미지)
      if (!isPositionLeft) {
        rotate = 90;
      }
    } else {
      // 아래 가르키는 화살표
      if (isPositionLeft) {
        rotate = -90;
      } else {
        rotate = 180;
      }
    }
    coachMarkArrow.style.transform = `rotate(${rotate}deg)`;
  }
}
