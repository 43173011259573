import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class TranslateVersionLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  // TODO: 추후 버전관리
  getTranslation(lang: string): Observable<any> {
    return this.httpClient.get(
      `/assets/i18n/${lang}.json?v=${new Date().valueOf()}`
    );
  }
}
