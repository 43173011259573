import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PwImgComponent } from './pw-img.component';

@NgModule({
  declarations: [PwImgComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [PwImgComponent],
})
export class PwImgModule {}
