import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 설정 저장소 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 저장 된 항목 조회
   * @param key 저장할 때 사용한 키
   */
  async getItem(key: string): Promise<any> {
    return this._flutterBridge.callHandler('preference', 'get', key);
  }

  /**
   * 항목 저장
   *
   * @param key 항목 저장 시에 사용할 키
   * @param value 저장할 항목
   */
  async setItem(key: string, value: any): Promise<boolean> {
    return this._flutterBridge.callHandler('preference', 'set', key, value);
  }
}
