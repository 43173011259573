import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PwLayoutService } from '../pw-layout.service';

@Component({
  selector: 'pw-content',
  templateUrl: './pw-content.component.html',
  styleUrls: ['./pw-content.component.scss'],
  host: { class: 'pw-content' },
  encapsulation: ViewEncapsulation.None,
})
export class PwContentComponent implements OnInit, AfterViewInit {
  /**
   * pw-content 스크롤 이벤트
   * @see `PwLayoutService.contentScroll$`
   */
  @Output() scroll: EventEmitter<Event> = this._pwLayoutService.contentScroll$;

  /**
   * 스크롤 엘리먼트 Ref
   */
  @ViewChild('scrollElement')
  private _scrollElement: ElementRef;

  /**
   * 스크롤 엘리먼트
   */
  scrollElement: HTMLElement;

  constructor(private _pwLayoutService: PwLayoutService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.scrollElement = this._scrollElement.nativeElement;
  }

  /**
   * pw-content 스크롤 이벤트 수신부
   */
  onScroll(event: Event): void {
    this.scroll.emit(event);
  }
}
