import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export interface HttpOptions {
  body?: any;
  headers?: {
    [header: string]: string | string[];
  };
  observe?: 'body' | 'events' | 'response';
  params?: {
    [param: string]: string | string[];
  };
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  reportProgress?: boolean;
  withCredentials?: boolean;
}

export abstract class AbstractHttpService {
  protected maxRetryCount = 3;

  constructor(protected httpClient: HttpClient) {
    this.httpAppender = this.httpAppender || ((o) => of(o));
    this.shouldRetry = this.shouldRetry || (() => false);
  }

  /**
   * 공통적으로 보내야 할 headers, params 등을 붙여줍니다.
   */
  abstract httpAppender(options: HttpOptions): Observable<HttpOptions>;

  /**
   * 오류 발생시 반복요청 할 조건을 정의합니다.
   */
  abstract shouldRetry(httpErrorResponse?: HttpErrorResponse): boolean;

  get(url: string, httpOptions?: HttpOptions): Observable<any> {
    return this._request('GET', url, httpOptions);
  }

  post(url: string, httpOptions?: HttpOptions): Observable<any> {
    return this._request('POST', url, httpOptions);
  }

  put(url: string, httpOptions?: HttpOptions): Observable<any> {
    return this._request('PUT', url, httpOptions);
  }

  patch(url: string, httpOptions?: HttpOptions): Observable<any> {
    return this._request('PATCH', url, httpOptions);
  }

  delete(url: string, httpOptions?: HttpOptions): Observable<any> {
    return this._request('DELETE', url, httpOptions);
  }

  private _request(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    url: string,
    httpOptions: HttpOptions = {},
    retryCount = 0
  ): Observable<any> {
    const oldOptions = {
      ...httpOptions,
      headers: httpOptions.headers || {},
      params: httpOptions.params || {},
    };

    // 토큰, 로그인정보 등 추가
    return this.httpAppender(oldOptions).pipe(
      mergeMap((newOptions = {}) => {
        return this.httpClient.request(method, url, newOptions);
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (!this.shouldRetry(httpErrorResponse)) {
          return throwError(httpErrorResponse);
        }

        // 실패 후 3회까지 반복
        if (retryCount === this.maxRetryCount) {
          return throwError(httpErrorResponse);
        }

        // 1초 후 재시도
        return timer(1000).pipe(
          mergeMap(() => this._request(method, url, oldOptions, retryCount + 1))
        );
      })
    );
  }
}
