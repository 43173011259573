import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwLocaleIconModule } from '../pw-locale-icon/pw-locale-icon.module';
import { PwLocaleViewerComponent } from './pw-locale-viewer.component';

@NgModule({
  declarations: [PwLocaleViewerComponent],
  imports: [CommonModule, PwLocaleIconModule],
  exports: [PwLocaleViewerComponent],
})
export class PwLocaleViewerModule {}
