import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-image-wrapper',
  templateUrl: './formly-image-wrapper.component.html',
  styleUrls: ['./formly-image-wrapper.component.scss'],
})
export class FormlyImageWrapperComponent extends FieldWrapper {
  public onClickImage(index: number): void {
    this.to.buttons[index].clickButton();
  }

  getImageLink(): string {
    if (this.formControl.value?.indexOf('paywith') > -1) {
      return `${this.formControl.value}?t=true`;
    }
    return this.formControl.value;
  }
}
