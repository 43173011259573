<h2 mat-dialog-title *ngIf="header">{{ header }}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
    >
    </formly-form>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="display: block !important">
  <button
    mat-raised-button
    [color]="isMobile ? 'nbg-cancel' : 'warn'"
    (click)="onClickCancle()"
  >
    취소
  </button>
  <button
    mat-raised-button
    [color]="isMobile ? 'nbg-ok' : 'primary'"
    *ngIf="hasOkButton"
    (click)="onClickOk()"
  >
    확인
  </button>
</mat-dialog-actions>
