import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BridgeService {
  get platform(): 'IOS' | 'AOS' | 'WEB' {
    const { userAgent } = navigator;
    if (userAgent.includes('Android')) return 'AOS';
    if (userAgent.includes('iPhone')) return 'IOS';
    return 'WEB';
  }
}
