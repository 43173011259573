import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 진동 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class VibrateService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 진동
   *
   * @param pattern 진동 패턴
   */
  async vibrate(pattern: number[]): Promise<boolean> {
    return this._flutterBridge.callHandler('vibrate', pattern);
  }
}
