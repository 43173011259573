import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwLocaleIconComponent } from './pw-locale-icon.component';

@NgModule({
  declarations: [PwLocaleIconComponent],
  imports: [CommonModule],
  exports: [PwLocaleIconComponent],
})
export class PwLocaleIconModule {}
