<ng-container #fieldComponent></ng-container>
<!-- 의도하지 않은 ClickEvent 방지하기 위해 div대신 form 사용 -->
<form class="button-container" *ngIf="hasButton">
  <ng-container *ngFor="let b of buttons; index as i">
    <button
      mat-raised-button
      *ngIf="b.canShow() || true"
      [color]="b.color || 'primary'"
      [disabled]="b.disabled() || false"
      [matBadge]="b.matBadge() || null"
      [matBadgePosition]="b.matBadgePosition() || 'after'"
      [matBadgeColor]="b.matBadgeColor() || 'warn'"
      (click)="onClickButton(i)"
    >
      {{ b.label }}
    </button>
  </ng-container>
</form>
