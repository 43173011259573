import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PwLocaleIconModule } from '../pw-locale-icon/pw-locale-icon.module';
import { PwLocaleInputComponent } from './pw-locale-input.component';

@NgModule({
  declarations: [PwLocaleInputComponent],
  imports: [
    CommonModule,
    PwLocaleIconModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatRippleModule,
  ],
  exports: [PwLocaleInputComponent],
})
export class PwLocaleInputModule {}
