import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  currentUrl$ = new BehaviorSubject<string>(undefined);

  constructor(private _router: Router) {
    this.setRouterEvent();
  }

  private setRouterEvent(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl$.next(event.urlAfterRedirects);
      }
    });
  }
}
