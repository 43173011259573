<ng-container
  *ngIf="systemPadding$ | async as systemPadding; else noSystemPadding"
>
  <div
    class="safearea-container"
    [ngStyle]="{
      height: !height
        ? 'calc(100%' +
          (useSystemTopPadding
            ? ' - ' + (systemPadding.top + additionalTopPadding) + 'px'
            : '') +
          (useSystemBottomPadding
            ? ' - ' + (systemPadding.bottom + addtionalBottomPadding) + 'px'
            : '') +
          ')'
        : height,
      'padding-top.px': useSystemTopPadding
        ? systemPadding.top + additionalTopPadding
        : additionalTopPadding,
      'padding-left.px': useSystemLeftPadding
        ? systemPadding.left + addtionalLeftPadding
        : addtionalLeftPadding,
      'padding-bottom.px': useSystemBottomPadding
        ? systemPadding.bottom + addtionalBottomPadding
        : addtionalBottomPadding,
      'padding-right.px': useSystemRightPadding
        ? systemPadding.right + addtionalRightPadding
        : addtionalRightPadding,
      'background-color': backgroundColor
    }"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-container>
<ng-template #noSystemPadding>
  <div
    class="safearea-container"
    [ngStyle]="{
      height: !height ? '100%' : height,
      'padding-top.px': additionalTopPadding,
      'padding-left.px': addtionalLeftPadding,
      'padding-bottom.px': addtionalBottomPadding,
      'padding-right.px': addtionalRightPadding,
      'background-color': backgroundColor
    }"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
