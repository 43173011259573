import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PwTuiEditorComponent } from './pw-tui-editor.component';

@NgModule({
  declarations: [PwTuiEditorComponent],
  imports: [CommonModule],
  exports: [PwTuiEditorComponent],
})
export class PwTuiEditorModule {}
