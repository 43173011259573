<mat-chip-list #chipList aria-label="Text Selection">
  <mat-chip
    *ngFor="let chip of chips"
    [selectable]="selectable"
    [removable]="removable"
    (removed)="remove(chip)"
  >
    {{ chip }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
  <input
    [placeholder]="to.label | translate"
    #chipsInput
    [formControl]="textCtrl"
    [matAutocomplete]="auto"
    [matChipInputAddOnBlur]="true"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
  />
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  <mat-option *ngFor="let item of filteredItems | async" [value]="item">
    {{ item }}
  </mat-option>
</mat-autocomplete>
