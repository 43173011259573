<a
  mat-list-item
  class="menu-list-item menu-item-{{ menu.class }}"
  [ngClass]="{ active: isRouterActive, expanded: expanded }"
  (click)="onClickMenu(menu)"
  *ngIf="!menu.canShow$ || (menu.canShow$ | async)"
  role="link"
  matRipple
>
  <div class="item-wrapper" [ngStyle]="{ 'padding-left': depth * 16 + 'px' }">
    <mat-icon class="menu-icon" *ngIf="menu.icon; else noIcon">
      {{ menu.icon }}
    </mat-icon>
    <span class="menu-name">
      <ng-template #noIcon>-&nbsp;</ng-template>
      {{ menu.name | translate }}
    </span>
    <mat-icon class="menu-arrow" [@rotate180]="expanded" *ngIf="hasChildren">
      expand_more
    </mat-icon>
  </div>
</a>
<div
  @slideUpDown
  @fadeInOut
  class="children-list"
  *ngIf="hasChildren && expanded"
>
  <app-nav-menu
    *ngFor="let child of menu.children"
    [menu]="child"
    [depth]="depth + 1"
    (clickMenu)="onClickMenu()"
  >
  </app-nav-menu>
</div>
