<input
  matInput
  type="url"
  autocomplete="off"
  [id]="id"
  [readonly]="to.readonly"
  [required]="to.required"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabindex]="to.tabindex"
  [placeholder]="to.placeholder"
/>
<input
  type="file"
  accept="image/*"
  (change)="onFileChange($event)"
  [hidden]="true"
  #imgUploader
/>
