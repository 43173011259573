import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Page } from '../models/page';
import { AbstractRepositoryService } from './abstract-repository.service';

/**
 * 즉각적인 CRUD에 사용하는 기존 RepositoryService 를 보완. 페이지 상태관리를 한다.
 *
 * T : angular 프로젝트 내에서 사용하는 모델 형식
 *
 * SP : api 서버에서 전달받는 페이지 형식
 *
 * 사용법 :
 *
 * 1. 상속받아서 사용하며 SP 형식을 Page<T> 형식으로 바꾸기 위한 메소드(parsePage)를 구현해야 한다
 *
 * 여러 api 에서 사용한다면(= 한 서버의 mrhst, user 등이 같은 형식이라면) AbstractPageRepositoryService -> "CommonPageRepositoryService" -> EntityService 와 같은 방식으로 중간 서비스를 생성한 후 parsePage 등을 공통화 한다
 */
export abstract class AbstractPageRepositoryService<
  T,
  SP
> extends AbstractRepositoryService<T, SP> {
  /**
   * 현재 보이는 목록 끝 페이지 순서(현재값. 0부터 시작)
   *
   * appendList를 사용할 때 뷰에서는 현재 페이지가 중요하지 않으므로 다음 페이지 argument 를 요청하지 않을 목적
   */
  listCurrentPageIdx = 0;

  /** 현재 검색조건 */
  private _list: Page<T> = null;

  private _page: Page<T> = null;

  private _listSubject: BehaviorSubject<Page<T>> = new BehaviorSubject(
    this._list
  );

  private _pageSubject: BehaviorSubject<Page<T>> = new BehaviorSubject(
    this._page
  );

  private _searchParams: { query?: any; body?: any } = {};

  /**
   * 현재 조건 해당 목록.
   *
   * 검색 조건으로 요청했던 마지막 페이지까지 가지고 있고, appendList() 실행하면 갱신된다
   */
  get list$(): Observable<Page<T>> {
    return this._listSubject.asObservable();
  }

  /**
   * 최근 페이지 검색 결과
   */
  get page$(): Observable<Page<T>> {
    return this._pageSubject.asObservable();
  }

  /** 검색 조건 변경. 이전 목록은 의미 없어지니 초기화도 진행 */
  setSearchParams(body = {}, query = {}): void {
    this._searchParams = {
      body,
      query,
    };

    this.initList();

    this.initPage();
  }

  /**
   * 현재 목록 초기화
   *
   * 최초 사용, 검색 조건 재 설정, 목록 갱신 시도 시 목록 초기화
   *
   * 첫 페이지 요청 부분 삭제(page 와 중복 요청 방지)
   */
  initList(): void {
    this.listCurrentPageIdx = 0;
    this._list = null;
  }

  /**
   * 다음 페이지를 요청하고, 현재 페이지 이후 목록을 덧붙인다
   */
  appendList(): void {
    if (this._list && this.listCurrentPageIdx >= this._list.totalPages) {
      throw new Error('페이지 값 초과');
    }

    Object.assign(this._searchParams.query, {
      page: this.listCurrentPageIdx,
    });

    this.listCurrentPageIdx += 1;

    this.getPage(this._searchParams.body, this._searchParams.query).subscribe(
      (res) => {
        if (!this._list) {
          this._list = res;
        } else {
          this._list.content.push(...res.content);
        }
        this._listSubject.next(this._list);
      }
    );
  }

  /**
   * 현재 페이지 정보 초기화
   *
   * 최초 사용, 검색 조건 재 설정 시 사용
   *
   * 첫 페이지 요청 부분 삭제(list 와 중복 요청 방지)
   */
  initPage(): void {
    this.listCurrentPageIdx = 0;
    this._page = null;
  }

  /**
   * 해당 페이지를 요청하고, 클래스 내 페이지 값을 변경한다
   */
  changePage(pageIdx: number): void {
    if (this._page && this._page.totalPages <= pageIdx) {
      throw new Error('페이지 값 초과');
    }

    Object.assign(this._searchParams.query, { page: pageIdx });

    this.getPage(this._searchParams.body, this._searchParams.query).subscribe(
      (res) => {
        this._page = res;
        this._pageSubject.next(this._page);
      }
    );
  }

  post(body: any = {}, params: any = {}): Observable<T> {
    return super.post(body, params).pipe(
      tap(() => {
        this.refreshData();
      })
    );
  }

  put(id: number, body: any = {}, params: any = {}): Observable<T> {
    return super.put(id, body, params).pipe(
      tap(() => {
        this.refreshData();
      })
    );
  }

  delete(id: number, body: any = {}, params: any = {}): Observable<T> {
    return super.delete(id, body, params).pipe(
      tap(() => {
        this.refreshData();
      })
    );
  }

  /**
   * 목록을 갱신한다.
   *
   * 데이터 작성, 변경 등 작업 후 실행
   */
  refreshData(): void {
    // 페이지 갱신
    // 현재 페이지 값을 별도로 가지고 있고, 초기화 이후 현재 페이지를 새로 요청하여 값 갱신
    const isUsingPage = this._page != null;
    if (isUsingPage) {
      const pageCurrentPageIdx = this._page.number;
      this.initPage();

      // 페이지를 사용하고 있었다면 페이지 다시 가져오기
      this.changePage(pageCurrentPageIdx);
    }

    // 현재 사용하고 있었다면 초기화 후 다시 값 가져오기
    const isUsingList = this._list != null;
    if (isUsingList) {
      // 리스트 갱신
      const listCurrentPageIdx = this.listCurrentPageIdx;

      // 기존 목록은 의미 없어지므로 삭제
      this.initList();

      // 누적 리스트를 사용하고 있었다면 리스트 다시 가져오기
      for (let i = 0; i <= listCurrentPageIdx; i++) {
        this.appendList();
      }
    }
  }
}
