import {
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Component,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'pw-html-viewer',
  templateUrl: './pw-html-viewer.component.html',
  styleUrls: ['./pw-html-viewer.component.scss'],
})
export class PwHtmlViewerComponent implements OnInit, OnChanges {
  @ViewChild('content') content: ElementRef<HTMLDivElement>;

  @Input() text = '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      if (this.content.nativeElement) {
        this.content.nativeElement.innerHTML = this.text;
      }
    }
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.content.nativeElement.innerHTML = this.text;
  }
}
