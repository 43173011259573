import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const hostPage = style({
  position: 'relative',
  width: '100vw',
  height: '100vh',
});

const staticPage = query(
  ':enter, :leave',
  [
    style({
      position: 'absolute',
      width: '100%',
      height: '100%',
      'z-index': 1,
    }),
  ],
  { optional: true }
);

const movingPage = style({
  position: 'fixed',
  'z-index': 2,
  background: 'white',
});

export const slideAnimation = trigger('routeAnimations', [
  transition('* => left', [
    hostPage,
    staticPage,
    query(
      ':enter',
      [
        movingPage,
        style({
          left: '100%',
          opacity: 0,
        }),
        animate('300ms ease', style({ left: '0%', opacity: 1 })),
      ],
      { optional: true }
    ),
  ]),
  transition('* => right', [
    hostPage,
    staticPage,
    query(
      ':leave',
      [
        movingPage,
        style({
          left: '0%',
          opacity: 1,
        }),
        animate('300ms ease', style({ left: '100%', opacity: 0 })),
      ],
      { optional: true }
    ),
  ]),
]);

export const slideUpDown = trigger('slideUpDown', [
  transition(':enter', [
    style({ height: '0px', minHeight: 0, 'overflow-y': 'hidden' }),
    animate('300ms ease-in-out', style({ height: '*' })),
  ]),
  transition(':leave', [
    animate(
      '300ms ease-in-out',
      style({ height: '0px', minHeight: 0, 'overflow-y': 'hidden' })
    ),
  ]),
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('300ms ease-in-out', style({ opacity: 0 }))]),
]);

export const rotate90 = trigger('rotate90', [
  state(
    'true',
    style({
      transform: 'rotate(90deg)',
    })
  ),
  state(
    'false',
    style({
      transform: 'rotate(0)',
    })
  ),
  transition('false => true', [
    animate('300ms ease-in-out', style({ transform: 'rotate(90deg)' })),
  ]),
  transition('true => false', [
    animate('300ms ease-in-out', style({ transform: 'rotate(0deg)' })),
  ]),
]);

export const rotate180 = trigger('rotate180', [
  state(
    'true',
    style({
      transform: 'rotate(180deg)',
    })
  ),
  state(
    'false',
    style({
      transform: 'rotate(0)',
    })
  ),
  transition('false => true', [
    animate('300ms ease-in-out', style({ transform: 'rotate(180deg)' })),
  ]),
  transition('true => false', [
    animate('300ms ease-in-out', style({ transform: 'rotate(360deg)' })),
  ]),
]);
