import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  COMPOSITION_BUFFER_MODE,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

let nextUniqueId = 0;

type InputType = 'text' | 'checkbox' | 'radio' | 'toggle';

/**
 * pwInput Directive, `pwInput`, `pwInput="radio"`, `pwInput="checkbox"`
 * @see `DefaultValueAccessor`
 * @deprecated 각 컴포넌트에서 input 태그 ng-content로 프로젝트 하여 사용할것
 */
@Directive({
  selector: `
  [pwInput],
  [pwInput=text],
  [pwInput=checkbox],
  [pwInput=radio],
  [pwInput=toggle],
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PwInputDirective,
      multi: true,
    },
  ],
})
export class PwInputDirective
  implements ControlValueAccessor, OnDestroy, OnInit {
  @Input('pwInput')
  get type(): InputType {
    return this._type;
  }
  set type(value: InputType) {
    this._type = value;
    this._setAttribute('type', this._type);
  }
  private _type: InputType;

  /**
   * 고유 id, 없으면 자동생성
   * @description
   * 디렉티브 적용한 컴포넌트에서 id 받고싶다면 `this._elementRef.nativeElement.id`로 호출. radio, checkbox 등 `<label for="id">`
   * 사용한다면 host 컴포넌트와 id가 겹치면 안됨. 컴포넌트에서 id 재가공할것.
   */
  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
    this._setAttribute('id', this._id);
  }
  private _id: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this._setAttribute('required', this._required);
  }
  private _required: boolean;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    // this._setAttribute('readonly', this._readonly);
    this._setAttribute('readonly', this._readonly);
  }
  private _readonly: boolean;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // this._setAttribute('disabled', this._disabled);
    this._setProperty('disabled', this._disabled);
  }
  private _disabled: boolean;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this._setProperty('placeholder', this._placeholder);
  }
  private _placeholder: string;

  @Input()
  get formControlName(): string {
    return this._formControlName;
  }
  set formControlName(value: string) {
    this._formControlName = value;
    this._setAttribute('formControlName', this._formControlName);
  }
  private _formControlName: string;

  @Input()
  get formControl(): FormControl {
    return this._formControl;
  }
  set formControl(value: FormControl) {
    this._formControl = value;
    this._setAttribute('formControl', this._formControl);
  }
  private _formControl: FormControl;

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._setProperty('name', this._name);
  }
  private _name: string;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
    this._setAttribute('value', this._value);
  }
  private _value: any;

  /**
   * The registered callback function called when an input event occurs on the input element.
   * @nodoc
   */
  onChange = (_: any) => {};

  /**
   * The registered callback function called when a blur event occurs on the input element.
   * @nodoc
   */
  onTouched = () => {};

  /** Whether the user is creating a composition string (IME events). */
  private _composing = false;

  /**
   * 이벤트 해제용 익명함수
   */
  private _unlisten = () => {};

  /**
   * 인풋 엘리먼트 있으면 반환, 없으면 생성해서 반환
   */
  get inputElement(): Element {
    if (!this._inputElement) {
      this._setInputElement();
      this._setInputListener();
    }
    return this._inputElement;
  }
  private _inputElement: Element;

  /**
   * 라이프사이클 제어용 init 여부 변수
   */
  private _isInit = false;

  /**
   * init 전에 input이 입력되는 라이프사이클 이슈를 처리하기 위한 익명함수 리스트
   */
  private _scheduledJobList: (() => void)[] = [];

  constructor(
    private _renderer2: Renderer2,
    private _elementRef: ElementRef,
    @Optional()
    @Inject(COMPOSITION_BUFFER_MODE)
    private _compositionMode: boolean
  ) {}

  ngOnInit(): void {
    this._isInit = true;

    // 라이프사이클 이슈로 실행 못했던 메소드 처리
    this._scheduledJobList.forEach((job) => job());

    this.type = this.type === 'toggle' ? 'checkbox' : this.type || 'text';
    this.id = `pw-${this.id || ++nextUniqueId}`;
    this.name = this.name || this.formControlName;

    // 컴포넌트에서 받을수 있도록 자신에게도 추가
    this._renderer2.setProperty(this._elementRef.nativeElement, 'id', this.id);
  }

  ngOnDestroy(): void {
    this._unlisten();
  }

  /**
   * Sets the "value" property on the input element.
   * @nodoc
   * @override
   */
  writeValue(value: any): void {
    this.valueChange(value);
  }

  /**
   * 인풋 값 변경
   * @param value 변경 값
   */
  valueChange(value: any): void {
    switch (this.type) {
      case 'checkbox': {
        this._setProperty('checked', !!value);
        break;
      }
      case 'toggle': {
        this._setProperty('checked', !!value);
        break;
      }
      case 'radio': {
        this._setProperty('checked', `${this.value}` === `${value}`);
        break;
      }
      default: {
        const normalizedValue = value == null ? '' : value;
        this._setProperty('value', normalizedValue);
        break;
      }
    }
  }

  /**
   * Registers a function called when the control value changes.
   * @nodoc
   * @override
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = (value) => {
      fn(value);
      this.valueChange(value);
    };
    // this.onChange = fn;
  }

  /**
   * Registers a function called when the control is touched.
   * @nodoc
   * @override
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Sets the "disabled" property on the input element.
   * @nodoc
   * @override
   */
  setDisabledState(isDisabled: boolean): void {
    this._setProperty('disabled', isDisabled);
  }

  /**
   * IME 입력 대응
   */
  private _handleInput(value: any): void {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      this.onChange(value);
    }
  }

  /**
   * IME 입력 대응
   */
  private _compositionStart(): void {
    this._composing = true;
  }

  /**
   * IME 입력 대응
   */
  private _compositionEnd(value: any): void {
    this._composing = false;
    this._compositionMode && this.onChange(value);
  }

  /**
   * inputElement가 있다면 첫번째 엘리먼트 반환, 없다면 새로 생성
   */
  private _setInputElement(): void {
    // 인풋 엘리먼트 검색
    const thisElement = this._elementRef.nativeElement as Document;
    const inputs: HTMLCollection = thisElement.getElementsByTagName('input');

    if (!inputs || inputs.length < 0) {
      throw new Error('인풋 태그를 추가하세요.');
    }

    if (inputs.length > 1) {
      throw new Error('동시에 하나의 인풋 태그만 생성 가능합니다.');
    }

    this._inputElement = inputs[0];
  }

  /**
   * inputElement 이벤트 등록 및 이벤트해제자 저장
   */
  private _setInputListener(): void {
    const listenerList: (() => void)[] = [];

    switch (this.type) {
      case 'checkbox': {
        listenerList.push(
          this._renderer2.listen(this._inputElement, 'change', ($event) =>
            this.onChange($event.target.checked)
          )
        );
        break;
      }
      case 'toggle': {
        listenerList.push(
          this._renderer2.listen(this._inputElement, 'change', ($event) =>
            this.onChange($event.target.checked)
          )
        );
        break;
      }
      case 'radio': {
        listenerList.push(
          this._renderer2.listen(this._inputElement, 'input', ($event) =>
            this.onChange($event.target.value)
          )
        );
        break;
      }
      default: {
        listenerList.push(
          this._renderer2.listen(this._inputElement, 'input', ($event) =>
            this._handleInput($event.target.value)
          )
        );

        listenerList.push(
          this._renderer2.listen(this._inputElement, 'compositionstart', () =>
            this._compositionStart()
          )
        );

        listenerList.push(
          this._renderer2.listen(
            this._inputElement,
            'compositionend',
            ($event) => this._compositionEnd($event.target.value)
          )
        );

        break;
      }
    }

    listenerList.push(
      this._renderer2.listen(this._inputElement, 'blur', () => this.onTouched())
    );

    // 다음 익명함수 실행시 이벤트 해제
    this._unlisten = () => {
      listenerList.forEach((listener) => listener());
    };
  }

  /**
   * @param key 인풋 어트리뷰트 이름
   * @param value 인풋 어트리뷰트 값
   */
  private _setAttribute(key: string, value: any): void {
    if (this._isInit) {
      // init 됐다면 즉시 실행 후 종료
      this._renderer2.setAttribute(this.inputElement, key, value);
      return;
    }

    // init 안됐다면 스케줄 등록
    this._scheduledJobList.push(() =>
      this._renderer2.setAttribute(this.inputElement, key, value)
    );
  }

  /**
   * @param key 인풋 프로퍼티 이름
   * @param value 인풋 프로퍼티 값
   */
  private _setProperty(key: string, value: any): void {
    if (this._isInit) {
      // init 됐다면 즉시 실행 후 종료
      this._renderer2.setProperty(this.inputElement, key, value);
      return;
    }

    // init 안됐다면 스케줄 등록
    this._scheduledJobList.push(() =>
      this._renderer2.setProperty(this.inputElement, key, value)
    );
  }
}
