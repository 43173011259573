import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 공유 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 텍스트 공유
   * @param text 공유할 텍스트
   */
  share(text: string): void {
    this._flutterBridge.callHandler('share', text);
  }
}
