import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pw-header',
  templateUrl: './pw-header.component.html',
  styleUrls: ['./pw-header.component.scss'],
  host: { class: 'pw-header' },
  encapsulation: ViewEncapsulation.None,
})
export class PwHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
