import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { FileService } from '../../../../repository/file.service';

@Component({
  selector: 'app-formly-image',
  templateUrl: './formly-image.component.html',
  styleUrls: ['./formly-image.component.scss'],
})
export class FormlyImageComponent extends FieldType implements OnInit {
  // @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;

  @ViewChild('imgUploader', { static: true })
  imgUploader: ElementRef<HTMLElement>;

  constructor(
    private _dialogService: DialogService,
    private _http: FileService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  public onFileChange(event: Event): void {
    const { files } = event.target as HTMLInputElement;
    if (!files || files.length < 1) {
      this._dialogService
        .alert('파일 업로드중 오류가 발생했습니다.')
        .subscribe();
      return;
    }

    const file = files[0];

    if (!/^image\//.test(file.type)) {
      this._dialogService.alert('이미지만 업로드 가능합니다.').subscribe();
      return;
    }

    this._http.upload(files[0]).subscribe((url) => {
      if (url) {
        this.formControl.patchValue(url);
      } else {
        this._dialogService
          .alert('파일 URL을 찾을수 없습니다. 관리자에게 문의해주세요.')
          .subscribe();
      }
    });
  }

  private init(): void {
    if (!this.to.buttons || this.to.buttons.length < 1) {
      this.to.buttons = [
        {
          label: '업로드',
          clickButton: () => this.clickUpload(),
          disabled: () => this.formControl.disabled,
        },
        {
          label: '제거',
          clickButton: () => this.clickDelete(),
          disabled: () => this.formControl.disabled,
        },
      ];
    } else {
      this.to.buttons[0].clickButton = () => this.clickUpload();
    }
  }

  private clickUpload(): void {
    this.imgUploader.nativeElement.click();
  }

  private clickDelete(): void {
    this.formControl.reset();
  }
}
