import { Injectable } from '@angular/core';

/**
 * 이미지 관련 처리 서비스. 이미지 축소 기능있으며 기능 추가 필요 시 개발
 * 
 * 
 */
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor() {}

  /** 
   * 지정한 크기 이하로 비율 유지하여 이미지를 줄인다. 이미 작다면 그대로 응답한다.
   */
  resizeImageSmall(src, newX?: number, newY?: number): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const prevX = img.width;
        const prevY = img.height;
        
        // 지정한 폭, 너비가 없다면 비율로 계산
        if (!newX) {
          if (newY) {
            newX = (newY / prevY) * prevX;
          } else {
            newX = prevX;
          }
        } else {
          if (newX > prevX) {
            // 목표 폭(축소되는 폭)이 오히려 작은 경우 리사이징하지 않음
            res(src);
          }
        }
        
        if (!newY) {
          newY = (newX / prevX) * prevY;
        }
        
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }
}
