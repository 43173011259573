import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 위/경도 정보가 담기는 객체
 */
export interface Coordinates {
  /**
   * 위도
   */
  latitude: number;
  /**
   * 경도
   */
  longitude: number;
}

/**
 * 주소 객체
 */
export interface Address {
  /**
   * 전체 주소
   */
  addressLine: string;
  /**
   * 관할 시/도
   */
  adminArea: string;
  /**
   * 좌표
   */
  coordinates: Coordinates;
  /**
   * 국가 코드
   */
  countryCode: string;
  /**
   * 국가명
   */
  countryName: string;
  featureName: string;
  locality: string;
  /**
   * 우편번호
   */
  postalCode: string;
  subAdminArea: string;
  /**
   * 군/구
   */
  subLocality: string;
  subThoroughfare: string;
  thoroughfare: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeocodeService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 주소로 조회
   * @param address 주소
   */
  async query(address: string): Promise<Address[]> {
    return this._flutterBridge.callHandler('geocode', 'query', address);
  }

  /**
   * 좌표로 조회
   * @param coordinates 좌표
   */
  async coordinates(coordinates: Coordinates): Promise<Address[]> {
    return this._flutterBridge.callHandler(
      'geocode',
      'coordinates',
      coordinates.latitude,
      coordinates.longitude
    );
  }
}
