import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean',
})
export class BooleanPipe implements PipeTransform {
  transform(value: any, lang = 'ko', ...args: unknown[]): string {
    // 값이 없으면 - 반환
    if (value === undefined || value === null) {
      // 없으면 반환
      return 'X';
    }

    return value === true ? 'O' : 'X';
  }
}
