import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PwLocaleTextareaComponent } from './pw-locale-textarea.component';

@NgModule({
  declarations: [PwLocaleTextareaComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatRippleModule,
  ],
  exports: [PwLocaleTextareaComponent],
})
export class PwLocaleTextareaModule {}
