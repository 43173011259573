import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * 위치 정보가 담긴 객체
 */
export interface LocationData {
  /**
   * 위도
   */
  latitude: number;

  /**
   * 경도
   */
  longitude: number;

  /**
   * 정확도
   */
  accuracy: number;

  /**
   * 고도
   */
  altitude: number;

  /**
   * 속도
   */
  speed: number;

  /**
   * 속도 정확도
   *
   * iOS에선 항상 0으로 반환
   */
  speedAccuracy: number;
  /**
   * 방위
   */
  heading: number;
  /**
   * 조회 시간
   */
  time: number;
}

/**
 * 위치 정보 조회하는 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 위치 정보 조회
   */
  async getLocation(): Promise<LocationData> {
    return this._flutterBridge.callHandler('getLocation');
  }
}
