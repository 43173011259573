<ng-container *ngFor="let locale of supportedLocales">
  <mat-form-field appearance="outline">
    <mat-label>
      <span>{{ locale?.name }}</span>
    </mat-label>
    <textarea
      matInput
      [rows]="rows"
      [disabled]="disabled"
      [required]="required"
      class="locale-input"
      [ngModel]="getLocaleModel(locale.value)"
      (ngModelChange)="onChangeCurrentValue($event, locale.value)"
    >
    </textarea>
  </mat-form-field>
</ng-container>
