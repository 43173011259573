import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'enum',
})
export class EnumPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: any,
    tsType?: string,
    lang = 'ko',
    ...args: unknown[]
  ): string {
    // 값이 없으면 - 반환
    if (value === undefined || value === null) {
      // 없으면 반환
      return '-';
    }

    return this.translateService.instant(`REPO.ENUM.${tsType}.${value}`);
  }
}
