import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-formly-rating',
  templateUrl: './formly-rating.component.html',
  styleUrls: ['./formly-rating.component.scss'],
})
export class FormlyRatingComponent extends FieldType {
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;
}
