import { Injectable } from '@angular/core';
import { FlutterBridgeService } from './flutter.service';

/**
 * undetermined: 필요하지 않음
 * granted: 사용자에 의해 허가됨
 * denied: 사용자에 의해 거부됨
 * restricted: iOS 스크린 타임의 개인 정보 보호 제한에 의해 제한됨
 * permanentlyDenied: 사용자에 의해 거부됐으며, 앱에서 더 이상 재요청을 할 수 없음
 */
type PermissionStatus =
  | 'undetermined'
  | 'granted'
  | 'denied'
  | 'restricted'
  | 'permanentlyDenied';

type PermissionType =
  | 'calendar'
  | 'camera'
  | 'contacts'
  | 'location'
  | 'locationAlways'
  | 'locationWhenInUse'
  | 'mediaLibrary'
  | 'microphone'
  | 'phone'
  | 'photos'
  | 'reminders'
  | 'sensors'
  | 'sms'
  | 'speech'
  | 'storage'
  | 'ignoreBatteryOptimizations'
  | 'notification'
  | 'accessMediaLocation'
  | 'activityRecognition';

/**
 * 권한 요청 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private _flutterBridge: FlutterBridgeService) {}

  /**
   * 권한 요청
   * @param type 요청 권한 종류
   */
  requestPermisstion(type: PermissionType): Promise<PermissionStatus> {
    return this._flutterBridge.callHandler(
      'requestPermission',
      this._getTypeCode(type)
    );
  }

  private _getTypeCode(type: PermissionType): number {
    switch (type) {
      case 'calendar':
        return 0;
      case 'camera':
        return 1;
      case 'contacts':
        return 2;
      case 'location':
        return 3;
      case 'locationAlways':
        return 4;
      case 'locationWhenInUse':
        return 5;
      case 'mediaLibrary':
        return 6;
      case 'microphone':
        return 7;
      case 'phone':
        return 8;
      case 'photos':
        return 9;
      case 'reminders':
        return 10;
      case 'sensors':
        return 11;
      case 'sms':
        return 12;
      case 'speech':
        return 13;
      case 'storage':
        return 14;
      case 'ignoreBatteryOptimizations':
        return 15;
      case 'notification':
        return 16;
      case 'accessMediaLocation':
        return 17;
      case 'activityRecognition':
        return 18;
      default:
        console.warn(`알 수 없는 권한 종류: ${type}`);
        return 19;
    }
  }
}
